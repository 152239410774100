const methods = {
    getTeams: {
      path: "/teams/list",
      method: "GET",
    },
    getTeamsByUser: {
      path: ({id}) => `/teams/user/${id}`,
      method: "GET",
    },
    getTeamsAvailableForMeetingsFilter: {
      path: "/teams/available-for-filter",
      method: "GET",
    },
    createTeam: {
      path: "/teams/new",
      method: "POST",
    },
    updateTeam: {
      path: ({id}) => `/teams/${id}`,
      method: "PUT",
    },
    deleteTeam: {
      path: ({id}) => `/teams/${id}`,
      method: "DELETE"
    },
    isTeamManager: {
      path: ({ userId }) => `/teams/is-team-manager/${userId}`,
      method: 'GET',
    },
}
export default methods;