const MetabaseIframe = ({ url, name }) => {
  return (
    <iframe
      title={name}
      width="100%"
      style={{ border: "none", height: "80vh" }}
      src={url}
    />
  );
};

export default MetabaseIframe;
