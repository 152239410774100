import { Dropdown, Menu, Select } from "antd";
import {
  MoreOutlined,
  FolderAddOutlined,
  TagsOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AccessControl } from "../permissions/AccessControl";
import { PERMISSIONS } from "../../app/utils";
import {
  hosts,
  updateMeetingHost,
  updateMeetingCallType,
} from "./filters/slice";
import { selectInteractionCallTypes } from "../team/interactionCallType/slice";
import { loadMeeting } from "../meeting/slice";
import { message } from "antd";
import { Phone } from "tabler-icons-react";
import { useUserHasPermission } from "../../hooks/permissions/useUserHasPermission";

const MeetingActions = ({ meeting, onLibrary, onTag }) => {
  const interactionCallTypes = useSelector(selectInteractionCallTypes);
  const canUpdateCallType = useUserHasPermission(PERMISSIONS.MEETINGS.UPDATE);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sellers = useSelector(hosts);

  const handleUpdateHost = (hostId) => {
    const host = sellers.find((s) => s.id === hostId);
    dispatch(
      updateMeetingHost({
        meetingId: meeting.id,
        hostId: host.id,
        hostName: host.name,
      })
    );
  };

  const handleUpdateCallType = async (record) => {
    const loadingMessage = message.loading({
      content: t("meetings.details.sections.calltype.updating"),
      duration: 0,
    });
    try {
      const meetingSelector = await loadMeeting(meeting.id); // ESto ayuda a traer el interactionId
      const meetingData = await dispatch(meetingSelector).unwrap();
      await dispatch(
        updateMeetingCallType({
          meetingId: meeting.id,
          callTypeId: record,
          interactionId: meetingData.interactionId,
          callTypeName:
            interactionCallTypes.find((item) => item._id === record).name || "",
        })
      ).unwrap();

      loadingMessage();
      message.success(t("meetings.details.sections.calltype.updatedsuccess"));
    } catch (error) {
      console.error("Error updating call type:", error);
      loadingMessage();
      message.error(t("meetings.details.sections.calltype.error"));
    }
  };

  const sellerOptions = (sellers || []).map((item, index) => ({
    key: `seller#update#${index}`,
    label: item.name,
    value: item.id,
  }));

  const interactionCallTypeOptions = (interactionCallTypes || []).map(
    (item, index) => ({
      key: `interactionCallType#update#${index}`,
      label: item.name,
      value: item._id,
    })
  );

  const menu = (
    <Menu>
      <Menu.Item
        icon={<FolderAddOutlined style={{ fontSize: 18, color: "#1791ff" }} />}
        onClick={onLibrary}
      >
        {t("actions.addToLibrary")}
      </Menu.Item>
      {canUpdateCallType && meeting.status !== "CALLTYPE_CHANGED" ? (
        <Menu.SubMenu
          title={t("actions.updateCallType")}
          icon={<Phone style={{ width: 18, height: 18, color: "#1791ff" }} />}
        >
          <Menu.Item>
            <Select
              showSearch
              placeholder={t("meetings.filters.selectCallType")}
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
              options={interactionCallTypeOptions}
              onChange={(callType) => handleUpdateCallType(callType)}
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(String(input).toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Menu.Item>
        </Menu.SubMenu>
      ) : (
        ""
      )}
      <Menu.Item
        icon={<TagsOutlined style={{ fontSize: 18, color: "#1791ff" }} />}
        onClick={onTag}
      >
        {t("actions.addTag")}
      </Menu.Item>
      <AccessControl permission={PERMISSIONS.MEETINGS.MODIFY_HOST}>
        <Menu.SubMenu
        title={t("actions.updateHost")}
        icon={<TrophyOutlined style={{ fontSize: 18, color: "#1791ff" }} />}
        >
          <Menu.Item>
            <Select
              showSearch
              placeholder={t("meetings.filters.selectHost")}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              onChange={(h) => handleUpdateHost(h)}
              options={sellerOptions}
              optionFilterProp="children"
              filterOption={(input, option) => String(option?.label ?? "").toLowerCase().includes(String(input).toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Menu.Item>
        </Menu.SubMenu>
      </AccessControl>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu}>
        <MoreOutlined
          style={{ fontSize: "15px", fontWeight: "bold", color: "#1791ff" }}
        />
      </Dropdown>
    </>
  );
};
export default MeetingActions;
