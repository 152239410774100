import styles from "../agenda/agenda.module.css";
import { Select, Typography, Calendar, Divider, ConfigProvider } from "antd";
import React, { useState } from "react";
import __ from "../../app/i18n";
import { getAntdLocaleByPrefix, removeAccents } from "../../app/utils";
import { useTranslation } from "react-i18next";
import { PERMISSIONS } from "../../app/utils";
import { AccessControl } from "../permissions/AccessControl";
import moment from "moment";

const Filters = ({ sellers, onFilter, defaultHost }) => {
  const { i18n } = useTranslation();
  const [host, setHost] = useState(defaultHost);
  const [date, setDate] = useState();

  const onChangeHost = (id, obj) => {
    setHost(obj);

    if (id) {
      onFilter({ host: { id, label: obj.label }, date });
    } else {
      onFilter({ host: null, date });
    }
  };

  const onChangeDate = (o) => {
    const date = {
      month: o.month() + 1,
      year: o.year(),
      day: o.format("D"),
      raw: moment(o).set({ hour: 0, minute: 0, second: 0 }),
    };
    setDate(date);

    const payload = { date, host: null }
    if (host) {
      payload.host = {
        id: (host.id || host.value),
        label: host.label
      };
    }

    onFilter(payload);
  };

  const sellersFilter = [];
  [...sellers]
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .forEach((seller) => {
      sellersFilter.push({ label: seller.name, value: seller.id });
    });

  return (
    <div>
      <div className={styles.filtersContent}>
          <AccessControl permission={PERMISSIONS.CALENDAR.TEAM_AGENDA}>
            <>
              <Typography.Title level={5}>
                {__("meetings.filters.host")}
              </Typography.Title>
              <Select
                allowClear
                onChange={(id, obj) => onChangeHost(id, obj)}
                style={{ width: "100%" }}
                options={sellersFilter}
                defaultValue={defaultHost}
                showSearch
                filterOption={(input, option) => removeAccents(String(option?.label ?? "").toLowerCase()).includes(removeAccents(String(input).toLowerCase()))}
              />
              <Divider />
            </>
          </AccessControl>
        

        <ConfigProvider locale={getAntdLocaleByPrefix(i18n.language)}>
        <Calendar fullscreen={false} onChange={onChangeDate} />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default Filters;
