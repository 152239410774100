import { Divider, Progress, Typography } from "antd";
import { WarningOutlined, CommentOutlined } from "@ant-design/icons";
import Section from "../../meeting/sections/Section";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export default function Tab({ data }) {
  const { t } = useTranslation();
  const { feedback, score, description, evaluables = [] } = data.properties;

  const totals = useMemo(() => {
    const totals = { total: 0, totalTrue: 0, toImprove: [] };

    evaluables.forEach((category) => {
      category.items.forEach((item) => {
        totals.total++;
        if (item.value) {
          totals.totalTrue++;
        }
      });
    });

    return totals;
  }, [evaluables]);

  if (!data.properties) {
    return (
      <Section key={data.id} sectionKey={data.id} title={data.name}>
        {t("noInformation")}
        <Divider />
      </Section>
    );
  }

  const itemsToImprove = [];
  evaluables.forEach((category) => {
    const categoryItemsToImprove = [];

    category.items.forEach((item) => {
      if (item.value) {
        return;
      }

      categoryItemsToImprove.push(
        <li style={{ marginBottom: "10px" }}>
          <Typography.Title level={5} type="secondary">
            {item.evaluable}
          </Typography.Title>
          {item.reason ? (
            <Typography.Text type="secondary">{item.reason}</Typography.Text>
          ) : (
            ""
          )}
        </li>
      );
    });

    if (categoryItemsToImprove.length) {
      itemsToImprove.push(
        <li style={{ padding: "10px 0px" }}>
          <Typography.Title level={5} style={{ color: "#1791ff" }}>
            {category.categoryName}
          </Typography.Title>
          <ul
            style={{
              margin: 0,
              padding: "0px 20px",
            }}
          >
            {categoryItemsToImprove}
          </ul>
        </li>
      );
    }
  });

  return (
    <Section key={data.id} sectionKey={data.id} title={t(data.name)}>
      <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
        <li
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography.Title level={5} style={{ color: "#1791ff" }}>
                {t("meetings.customScore.score")}
              </Typography.Title>
              <Progress
                style={{ marginTop: "10px" }}
                type="circle"
                percent={score * 10}
                format={(x) => x / 10}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography.Title level={5} style={{ color: "#1791ff" }}>
                {t("meetings.customScore.totalItemsFound")}
              </Typography.Title>
              <Progress
                style={{ marginTop: "10px" }}
                type="circle"
                percent={(totals.totalTrue * 100) / totals.total}
                format={() => totals.totalTrue + "/" + totals.total}
              />
            </div>
          </div>
          <Divider />
        </li>
        {feedback ? (
          <li style={{ marginBottom: "15px" }}>
            <Typography.Title level={5} style={{ color: "#1791ff" }}>
              <CommentOutlined /> {t("meetings.customScore.feedback")}
            </Typography.Title>
            <Typography.Text type="secondary">{feedback}</Typography.Text>
            <Divider />
          </li>
        ) : (
          ""
        )}
        {itemsToImprove ? (
          <>
            <li style={{ marginBottom: "15px" }}>
              <Typography.Title level={5} style={{ color: "#1791ff" }}>
                <WarningOutlined /> {t("meetings.customScore.toImprove")}
              </Typography.Title>
              <ul style={{ margin: 0, padding: "0px 20px" }}>
                {itemsToImprove}
              </ul>
            </li>
          </>
        ) : (
          ""
        )}
      </ul>
    </Section>
  );
}
