import {
  Col,
  Menu,
  message,
  Modal,
  Row,
  Typography,
  Button,
  Tooltip,
  Alert,
  Tag,
} from "antd";
import styles from "./MeetingDetailHeader.module.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteMeeting, openTeamSection, selectMeeting, updateMeeting } from "./slice";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  EyeOutlined,
  TeamOutlined,
  FolderAddOutlined,
  TagOutlined,
  DeleteOutlined,
  LockOutlined,
  ShareAltOutlined,
  EditOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LibraryModal from "../library/Modal";
import { selectUser } from "../session/slice";
import { SamuScoreItem } from "../meetings/SamuScoreItem";
import PrivacyModal from "../privacy/Modal";
import MeetingTags from "../tags/MeetingTags";
import TagsModal from "../tags/TagsModal";
import ShareMeetingModal from "../share/ShareMeetingModal";
import { selectInteractionCallTypes } from "../team/interactionCallType/slice";
import { PERMISSIONS } from "../../app/utils";
import Api from "../../app/api";
import { showGlobalErrors } from "../loading/slice";
import { useUserHasPermission } from "../../hooks/permissions/useUserHasPermission";
import { AccessControl } from "../permissions/AccessControl";
import useUserIsManager from "../../hooks/permissions/useUserIsManager";
import { selectUsers, selectSpeakers, loadUsers } from "../team/users/slice";
import MeetingDetailHeaderEdit from "./MeetingDetailHeaderEdit";

export default function MeetingDetailHeader() {
  const meeting = useSelector(selectMeeting);
  const user = useSelector(selectUser)
  const isManager = useUserIsManager();
  const canDeleteAllMeeting = useUserHasPermission(PERMISSIONS.MEETINGS.DELETE_ALL)
  const canDeleteMeetingFromSameTeam = useUserHasPermission(PERMISSIONS.MEETINGS.DELETE_TEAM_HOST)
  const canUpdateMeeting = useUserHasPermission([
    PERMISSIONS.MEETINGS.UPDATE,
    PERMISSIONS.MEETINGS.MODIFY_MEETING_NAME,
  ], false);
  const users = useSelector(selectUsers);
  const stakeholders = useSelector(selectSpeakers);
  const [messageApi, contextHolder] = message.useMessage();
  const [shareModalOpen, setShareModalOpen] = useState();
  const [libraryModalOpen, setLibraryModalOpen] = useState();
  const [tagModalOpen, setTagModalOpen] = useState();
  const [privacyModalOpen, setPrivacyModalOpen] = useState();
  const [modalOpen, setModalOpen] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [canDeleteMeeting, setCanDeleteMeeting] = useState(false);
  const callTypes = useSelector(selectInteractionCallTypes);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editModalLoading, setEditModalLoading] = useState(false);
  const [callTypeChanged, setCallTypeChanged] = useState(false);

  useEffect(() => {
    // This was put in because if the users are not uploaded the host will not be displayed correctly 
    if(!users.length) {
      dispatch(loadUsers());
    }
  }, [dispatch, users.length]);

  const isMyUserManager = useMemo(() => isManager(user), [isManager, user])

  const loadTeamsByUser = useCallback(async (id) => {
    const response = await Api("getTeamsByUser", { id });
  
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
      return [];
    }
  
    return response || [];
  }, [dispatch]);
  
  const hasCommonTeam = useCallback((userTeams, hostTeams) => {
    const userTeamsIds = new Set(userTeams.map((team) => team._id));
    return hostTeams.some((team) => userTeamsIds.has(team._id));
  }, []);
  
  useEffect(() => {
    const checkDeleteMeetingPermissions = async () => {

      if(canDeleteAllMeeting) {
        setCanDeleteMeeting(true);
      }
      if (!canDeleteAllMeeting && !canDeleteMeetingFromSameTeam) {
        setCanDeleteMeeting(false);
      }
  
      if (!canDeleteAllMeeting && canDeleteMeetingFromSameTeam) {
        const [hostTeams, userTeams] = await Promise.all([
          loadTeamsByUser(meeting.hostId),
          loadTeamsByUser(user._id),
        ]);

        if (hasCommonTeam(userTeams, hostTeams)) {
          setCanDeleteMeeting(true);
        }
      }
      return
    };
  
    checkDeleteMeetingPermissions();
  }, [canDeleteAllMeeting, canDeleteMeetingFromSameTeam, loadTeamsByUser, meeting.host, user._id, hasCommonTeam, meeting.hostId]);

  const { t } = useTranslation();

  useEffect(() => {
    if (meeting.status === "CALLTYPE_CHANGED") {
      setCallTypeChanged(true);
    }
  }, [meeting]);

  const handleOpenSection = (section) => {
    dispatch(openTeamSection(section));
  };

  const openLibraryModal = () => {
    setLibraryModalOpen(true);
  };

  const openTagModal = () => {
    setTagModalOpen(true);
  };

  const openPrivacyModal = () => {
    setPrivacyModalOpen(true);
  };

  const openShareModal = () => {
    setShareModalOpen(true);
  };

  const confirmDeleteMeeting = () => {
    setModalOpen(true);
  };

  const handleDeleteMeeting = () => {
    dispatch(deleteMeeting({ id: meeting.id }));

    return navigate("/meetings");
  };

  const handleEditSubmit = async (values) => {
    setEditModalLoading(true);
    try {
      const query = {
        id: meeting.id,
        account: meeting.accountId,
        interactionId: meeting.interactionId,
        name: values.name,
        hostId: values.hostId,
        userIds: values.userIds,
        stakeholderIds: values.stakeholderIds,
        callTypeId: values.callTypeId,
      };

      dispatch(updateMeeting(query));

      messageApi.open({
        type: "success",
        content: t("generic.alerts.meetingUpdated"),
      });

      setCallTypeChanged(!!values.callTypeId);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: t("generic.alerts.error"),
      });
    }
    setEditModalLoading(false);
  };

  if (meeting && meeting.id && meeting.dateFrom) {
    return (
      <>
        <Row
          style={{ paddingBottom: ".5rem" }}
          className={styles.container + " meeting-header-detail-container"}
        >
          <Col
            xs={24}
            md={17}
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "flex-start",
            }}
          >
            <SamuScoreItem size={50} meeting={meeting} />
            <div>
              {contextHolder}
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <Typography.Title level={5} className={styles.meetingName}>
                  {meeting.name}
                </Typography.Title>
                {canUpdateMeeting && (
                  <>
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      onClick={() => setIsEditModalVisible(true)}
                    />
                    {meeting.manuallyScheduled &&
                      (!meeting.stakeholders ||
                        meeting.stakeholders.length === 0) && (
                        <Tooltip title={t("generic.alerts.noMeetingInfo")}>
                          <WarningOutlined style={{ color: "#faad14" }} />
                        </Tooltip>
                      )}
                  </>
                )}
              </div>
              <Typography.Text className={styles.meetingDate} type="secondary">
                {moment(meeting.dateFrom).format("Do").replace("º", "") +
                  " de " +
                  moment(meeting.dateFrom).format("MMMM") +
                  " del " +
                  moment(meeting.dateFrom).format("YYYY")}{" "}
                ⋅ {moment(meeting.dateFrom).format("HH:mm")}
              </Typography.Text>
            </div>
            <MeetingTags
              callTypes={callTypes}
              meeting={meeting}
              style={{ alignSelf: "flex-start", marginTop: "4px" }}
            />
          </Col>
          <Col xs={24} md={7}>
            <Menu
              style={{ textAlign: "right", borderBottom: "none" }}
              mode="horizontal"
            >
              <Menu.Item
                style={{ width: "auto", display: "inline-block" }}
                key="share-meeting"
                icon={<ShareAltOutlined className={styles.menuItemLogo} />}
                onClick={() => openShareModal()}
              />

              {isMyUserManager ? (
                <Menu.Item
                  style={{ width: "auto", display: "inline-block" }}
                  key="visits-section"
                  icon={<EyeOutlined className={styles.menuItemLogo} />}
                  onClick={() => handleOpenSection("visits")}
                />
              ) : (
                ""
              )}

              <Menu.Item
                style={{ width: "auto", display: "inline-block" }}
                key="comments-section"
                icon={<TeamOutlined className={styles.menuItemLogo} />}
                onClick={() => handleOpenSection("comments")}
              />

              <AccessControl permission={PERMISSIONS.LIBRARY.UPDATE}>
                <Menu.Item
                  style={{ width: "auto", display: "inline-block" }}
                  key="add-to-library-meeting"
                  icon={
                    <FolderAddOutlined
                      className={styles.menuItemLogo}
                      key="ellipsis"
                    />
                  }
                onClick={() => openLibraryModal()}
              />
              </AccessControl>

              <Menu.Item
                style={{ width: "auto", display: "inline-block" }}
                key="add-tag-meeting"
                icon={
                  <TagOutlined className={styles.menuItemLogo} key="ellipsis" />
                }
                onClick={() => openTagModal()}
              />

              <AccessControl permission={PERMISSIONS.MEETINGS.CHANGE_PRIVACY}>
                <Menu.Item
                  style={{ width: "auto", display: "inline-block" }}
                  key="privacy-meeting"
                  icon={<LockOutlined className={styles.menuItemLogo} />}
                  onClick={() => openPrivacyModal()}
                />
              </AccessControl>

              {canDeleteMeeting && (
                <Menu.Item
                  style={{ width: "auto", display: "inline-block" }}
                  key="delete-meeting"
                  icon={<DeleteOutlined className={styles.menuItemLogo} />}
                  onClick={() => confirmDeleteMeeting()}
                />
              )}
            </Menu>

            {canDeleteMeeting && (
              <Modal
                title={t("generic.fields.needConfirmTitle")}
                open={modalOpen}
                onOk={() => handleDeleteMeeting()}
                onCancel={() => setModalOpen(false)}
                okText={t("generic.fields.confirm")}
                cancelText={t("generic.fields.cancel")}
              >
              <p>{t("generic.fields.needConfirmContent")}</p>
              </Modal>
            )}
            <AccessControl permission={PERMISSIONS.LIBRARY.UPDATE}>
              <LibraryModal
                meeting={meeting}
                open={libraryModalOpen}
                onClose={() => setLibraryModalOpen(false)}
              />
            </AccessControl>
            <TagsModal
              meeting={meeting}
              open={tagModalOpen}
              onClose={() => setTagModalOpen(false)}
            />
            <AccessControl permission={PERMISSIONS.MEETINGS.CHANGE_PRIVACY}>
              <PrivacyModal
                meeting={meeting}
                open={privacyModalOpen}
                onClose={() => setPrivacyModalOpen(false)}
              />
            </AccessControl>
            <ShareMeetingModal
              meeting={meeting}
              open={shareModalOpen}
              onClose={() => setShareModalOpen(false)}
            />
          </Col>

          <AccessControl permission={[PERMISSIONS.MEETINGS.UPDATE, PERMISSIONS.MEETINGS.MODIFY_MEETING_NAME]} requireAll={false}>
            <MeetingDetailHeaderEdit
              visible={isEditModalVisible}
              onCancel={() => setIsEditModalVisible(false)}
              onOk={handleEditSubmit}
              meeting={meeting}
              loading={editModalLoading}
              users={users}
              stakeholders={stakeholders}
              callTypes={callTypes}
            />
          </AccessControl>
        </Row>

        {callTypeChanged ? (
          <Alert
            showIcon
            message={t("meetings.details.sections.edit.callTypeChangedWarning")}
            type="warning"
            style={{ margin: "5px 10px" }}
          />
        ) : (
          ""
        )}
      </>
    );
  } else {
    return <></>;
  }
}
