import { Navigate } from "react-router-dom";
import { useUserHasPermission } from "../hooks/permissions/useUserHasPermission";

export const ProtectedRoute = ({ permissions, requireAll = true ,children }) => {
  const hasPermission = useUserHasPermission(permissions, requireAll);

  if (!hasPermission) {
    return <Navigate to="/meetings" replace />;
  }

  return children;
};

