import { useSelector } from "react-redux";
import { selectUser } from "../../features/session/slice";

export const usePermissionStatus = (permissions) => {
  const user = useSelector(selectUser);

  if (!user || !user.access) {
    return {};
  }

  if (Array.isArray(permissions)) {
    return permissions.reduce((acc, permission) => {
      acc[permission] = user.access.some(
        (access) =>
          Array.isArray(access?.permissions) &&
          access.permissions.some((p) => p.key === permission)
      );
      return acc;
    }, {});
  }
};
