import { useSelector } from "react-redux";
import LoggedIn from "./LoggedIn";
import LoginForm from "./LoginForm";
import ExtensionLoginForm from "./ExtensionLoginForm";
import { selectIsLoggedIn } from "./slice";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { message } from "antd";
import __ from "../../app/i18n";

const Check = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has('no_access')) {
      message.error(__('generic.errors.not_authorized'), 4);

      setTimeout(() => {
        params.delete('no_access');
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
      }, 2000);
    }
  }, [location, navigate]);

  if (location.pathname === '/extension/login') {
    return <ExtensionLoginForm />;
  }

  if (location.pathname.includes('set-password') || location.pathname.includes('share')) {
    return;
  }

  return isLoggedIn ? <LoggedIn>{children}</LoggedIn> : <LoginForm />;
};

export default Check;
