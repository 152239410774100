import { Drawer, Button, Form, Input, Checkbox, Select, Tag, Alert, Modal} from "antd";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "./slice";
import { selectTeams } from "../teams/slice";
import { removeAccents } from "../../../app/utils";
import { useTranslation } from "react-i18next";
import { useUsersWhoCanRecord } from "../../../hooks/permissions/useUsersWhoCanRecord";
import { useRecordingRoles } from "../../../hooks/permissions/useRecordingRoles";
import __ from "../../../app/i18n";
import { selectRoles } from "../roles/slice";

export default function NewTeamUser({ open, onClose, seats }) {
  const dispatch = useDispatch();
  const { hasRecordingRole } = useRecordingRoles();
  const usersWhoCanRecord = useUsersWhoCanRecord();
  const teams = useSelector(selectTeams);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false);
  const [selectAllTeams, setSelectAllTeams] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const roles = useSelector(selectRoles);

  const roleDefault = useMemo(() => {
    const roleDefault = roles.find(role => role.name === "Collaborator");
    return roleDefault;
  }, [roles]);

  const hasReachedLicenseLimit = useMemo(() => {
    return usersWhoCanRecord.length >= seats;
  }, [usersWhoCanRecord, seats]);

  const handleSelectAllTeamsChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAllTeams(isChecked);
    const newTeams = isChecked ? teams.map(team => team._id) : [];
    setSelectedTeams(newTeams);
  };

  const handleTeamsChange = (value) => {
    setSelectedTeams(value);
    setSelectAllTeams(value.length === teams.length);
  };

  const handleNewUser = (values) => {
    dispatch(createUser({ 
      ...values,
      sendWelcomeEmail,
      teams: selectAllTeams ? teams.map(team => team._id) : selectedTeams,
      access: selectedRoles
    }));
  };

  const handleRolesChange = (value) => {
    console.log(hasRecordingRole(value),!hasRecordingRole(selectedRoles), hasReachedLicenseLimit, "anjnqjsnw")
    if (hasRecordingRole(value) && !hasRecordingRole(selectedRoles) && hasReachedLicenseLimit) {
      Modal.warning({
        title: t("team.licenseLimit.warning"),
        content: t("team.licenseLimit.description"),
      });
      form.setFieldValue("access", selectedRoles);
      return;
    } else {
      setSelectedRoles(value);
    }
  };

  return (
    <Drawer
      title={t("team.list.newUser")}
      placement={"right"}
      width={500}
      onClose={() => onClose()}
      open={open}
      getContainer={true}
    >
      {hasReachedLicenseLimit && (
          <Alert
            message={t("team.licenseLimit.warning")}
            description={t("team.licenseLimit.description")}
            type="warning"
            showIcon
            style={{ marginBottom: "16px" }}
          />
        )}
      <Form
        name="basic"
        layout="vertical"
        onFinish={handleNewUser}
        autoComplete="off"
        initialValues={{
          access: [roleDefault?._id],
        }}
        form={form}
      >
        <Form.Item
          name="name"
          label={t("generic.fields.name")}
          rules={[
            {
              required: true,
              message: t("generic.required.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={t("generic.fields.lastName")}
          rules={[
            {
              required: true,
              message: t("generic.required.lastName"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: t("generic.required.email"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="access"
          label={__("team.roles")}
          rules={[
            {
              required: true,
              message: __("generic.required.userRole"),
            },
          ]}
        >
          <Select
            mode="multiple"
            onChange={handleRolesChange}
            style={{ width: "100%" }}
            optionLabelProp="label"
            filterOption={(typed, item) => {
              const contains = String(item.label)
                .toUpperCase()
                .startsWith(String(removeAccents(typed)).toUpperCase());
              return contains;
            }}
          >
            {roles.map((role) => (
              <Select.Option key={role._id} value={role._id} label={role.name}>
                <span>{role.name}</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item style={{ marginBottom: "2px" }}>
          <Checkbox
            checked={selectAllTeams}
            onChange={handleSelectAllTeamsChange}
          >
            {t("team.newTeam.selectAllTeams")}
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="teams"
          label={t("team.teams")}
        >
          <Select
            mode="multiple"
            disabled={selectAllTeams}
            value={selectedTeams}
            onChange={handleTeamsChange}
            style={{ width: '100%' }}
            optionLabelProp="label"
            filterOption={(typed, item) => {
              const contains = String(item.label)
                .toUpperCase()
                .startsWith(String(removeAccents(typed)).toUpperCase());
              return contains;
            }}
          >
            {teams.map(team => (
              <Select.Option 
                key={team._id} 
                value={team._id}
                label={team.name}
              >
                <Tag color={team.color || 'blue'}>{team.name}</Tag>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("generic.fields.sendWelcomeEmail")}
          name="sendWelcomeEmail"
        >
          <Checkbox name="sendWelcomeEmail" onChange={(e) => setSendWelcomeEmail(e.target.checked)}/>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("generic.fields.createUser")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
