import React, { useEffect, useMemo, useState } from "react";
import Api from "../../app/api";
import { loadUsers, selectUsers } from "../../features/team/users/slice";
import { useDispatch, useSelector } from "react-redux";

export const useConnectedCalendarsCount = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const [data, setData] = useState([]);

  useEffect(() => {
    if(users.length === 0) {
      dispatch(loadUsers());
    }
  }, [dispatch, users.length]);

  useEffect(() => {
    const getConnections = async () => {
      const data = await Api("googleConnections", {});
      setData(data);
    };
    getConnections();
  }, []);

  const connectedCalendarsNumber = useMemo(() => {
    let connectedCalendars = 0;
    for (const user of users) {
      if (
        data.usersWithGoogle?.includes(user.id) ||
        data.usersWithMicrosoft?.includes(user.id)
      ) {
        connectedCalendars++;
      }
    }
    return connectedCalendars;
  }, [data, users]);

  return connectedCalendarsNumber;
};
