import { configureStore } from "@reduxjs/toolkit";
import coachingReducer from "../features/coaching/slice";
import counterReducer from "../features/counter/counterSlice";
import filtersReducer from "../features/meetings/filters/slice";
import meetingReducer from "../features/meeting/slice";
import sessionReducer from "../features/session/slice";
import teamReducer from "../features/team/users/slice";
import loadingReducer from "../features/loading/slice";
import agendaReducer from "../features/agenda/slice";
import notificationsReducer from "../features/notifications/slice";
import libraryReducer from "../features/library/slice";
import aiReducer from "../features/meeting/sections/ai/slice";
import callTypeReducer from "../features/meetings/filters/calltypes/slice";
import analyticsReducer from "../features/analytics/slice";
import comparingAnalyticsReducer from "../features/analytics/Compare/slice";
import tagsReducer from "../features/tags/slice";
import marketplaceReducer from "../features/marketplace/slice";
import conversationReducer from "../features/conversation/slice";
import profileReducer from "../features/team/general/profile/slice";
import interactionCallTypesReducer from "../features/team/interactionCallType/slice";
import teamsReducer from "../features/team/teams/slice";
import rolesReducer from "../features/team/roles/slice";
import permissionReducer from "../features/team/permissions/slice";
import googleCalendarReducer from "../features/team/general/profile/tabs/slices/googleSlice";
import microsoftCalendarReducer from "../features/team/general/profile/tabs/slices/microsoftSlice";
import organizationReducer from "../features/team/organization/slice";

export const store = configureStore({
  reducer: {
    coaching: coachingReducer,
    counter: counterReducer,
    filters: filtersReducer,
    meeting: meetingReducer,
    session: sessionReducer,
    team: teamReducer,
    agenda: agendaReducer,
    loading: loadingReducer,
    notifications: notificationsReducer,
    library: libraryReducer,
    ai: aiReducer,
    callType: callTypeReducer,
    analytics: analyticsReducer,
    compareAnalytics: comparingAnalyticsReducer,
    tag: tagsReducer,
    marketplace: marketplaceReducer,
    conversation: conversationReducer,
    profile: profileReducer,
    interactionCallType: interactionCallTypesReducer,
    teams: teamsReducer,
    roles: rolesReducer,
    permissions: permissionReducer,
    googleCalendar: googleCalendarReducer,
    microsoftCalendar: microsoftCalendarReducer,
    organization: organizationReducer,
  },
});
