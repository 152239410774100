import { Checkbox } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Permission } from "./Permission";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPermissions,
  selectSelectedPermissionIds,
  setSelectedPermissionIds,
} from "../permissions/slice";
import styles from "./role.module.css"
import { useTranslation } from "react-i18next";

const getPermissionsByCategory = (permissions, categoryKey) => {
  return permissions.filter(
    (permission) => permission.category.key === categoryKey
  );
};

export const PermissionCategory = ({ category, isPreview = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const permissionsDefault = useSelector(selectPermissions);
  const selectedPermissionIds = useSelector(selectSelectedPermissionIds);
  const [permissions, setPermissions] = useState([]);


  const permissionsByCategory = useMemo(
    () => getPermissionsByCategory(permissionsDefault, category.category.key),
    [permissionsDefault, category.category.key]
  );

  useEffect(() => {
    setPermissions(
      permissionsByCategory.filter((p) => selectedPermissionIds.includes(p._id))
    );
  }, [selectedPermissionIds, permissionsByCategory]);

  const isAllSelected = useMemo(() => {
    return (
      permissions.length > 0 &&
      permissionsByCategory.length > 0 &&
      permissionsByCategory.every((permission) =>
        selectedPermissionIds.includes(permission._id)
      )
    );
  }, [permissions, permissionsByCategory, selectedPermissionIds]);

  const handleChangeSelectAll = (e) => {
    if (e.target.checked) {
      const allPermissionIds = permissionsByCategory.map((p) => p._id);
      dispatch(setSelectedPermissionIds([...new Set([...selectedPermissionIds, ...allPermissionIds])]));
    } else {
      dispatch(setSelectedPermissionIds(selectedPermissionIds.filter((id) => !permissionsByCategory.some((p) => p._id === id))));
    }
  };

  return (
    permissionsByCategory.length > 0 && (
      <div className={styles.permissionCategoryContainer}>
        <div
          className={styles.permissionCategoryCheckbox}
        >
          <Checkbox checked={isAllSelected} onChange={(e) => {
            !isPreview && handleChangeSelectAll(e);
          }}>
            {t("generic.buttons.selectAll")}
          </Checkbox>
        </div>
        {permissionsByCategory.map((permissionDefault) => (
          <Permission
            key={permissionDefault.key}
            permission={permissionDefault}
            selected={permissions.some((p) => p._id === permissionDefault._id)}
            isPreview={isPreview}
          />
        ))}
      </div>
    )
  );
};
