import { Button, Checkbox, Drawer, Form, Input, message } from "antd";
import __ from "../../../app/i18n";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPermissions,
  selectSelectedPermissionIds,
  setSelectedPermissionIds,
} from "../permissions/slice";
import { PermissionCategoryList } from "./PermissionCategoryList";
import { useEffect, useMemo, useState } from "react";
import { updateRole } from "./slice";
import { groupPermissionsByCategory } from "./utils";
import styles from "./role.module.css";

export default function EditRole({ open, onClose, role }) {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const allPermissions = useSelector(selectPermissions);
  const selectedPermissions = useSelector(selectSelectedPermissionIds);
  const [selectAllState, setSelectAllState] = useState(
    selectedPermissions.length === allPermissions.length
  );

  const isAllSelected = useMemo(() => {
    return (
      selectedPermissions.length > 0 &&
      allPermissions.length > 0 &&
      allPermissions.every((permission) =>
        selectedPermissions.includes(permission._id)
      )
    );
  }, [selectedPermissions, allPermissions]);

  const handleSelectAllPermissions = (e) => {
    if (e.target.checked) {
      setSelectAllState(true);
      dispatch(setSelectedPermissionIds(allPermissions.map((p) => p._id)));
    } else {
      setSelectAllState(false);
      dispatch(setSelectedPermissionIds([]));
    }
  };

  const handleSave = (values) => {
    if (selectedPermissions.length === 0) {
      messageApi.open({
        type: "error",
        content: __("generic.errors.no_permissions_selected"),
      });
      return;
    } else {
      dispatch(
        updateRole({
          id: role._id,
          name: values.roleName,
          permissions: selectedPermissions,
        })
      );
      dispatch(setSelectedPermissionIds([]));
      onClose();
    }
  };

  useEffect(() => {
    if (role) {
      dispatch(setSelectedPermissionIds(role.permissions.map((p) => p._id)));
    }
  }, [dispatch, role]);

  return (
    role &&
    allPermissions && (
      <>
        {contextHolder}
        <Drawer
          title={"Roles"}
          zIndex={999}
          placement={"right"}
          width={500}
          onClose={onClose}
          open={open}
          getContainer={true}
        >
          <Form
            name="editRole"
            layout="vertical"
            onFinish={handleSave}
            autoComplete="off"
          >
            <Form.Item
              name="roleName"
              label={__("generic.fields.role")}
              rules={[{ required: true, message: __("generic.required.role") }]}
              className={styles.formInput}
              initialValue={role?.name}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {__("meetings.filters.save")}
              </Button>
            </Form.Item>
            <Form.Item>
              <Checkbox
                checked={isAllSelected}
                onChange={handleSelectAllPermissions}
              >
                {__("generic.buttons.selectAll")}
              </Checkbox>
            </Form.Item>
            <PermissionCategoryList
              categories={groupPermissionsByCategory(
                allPermissions,
                role?.permissions
              )}
            />
          </Form>
        </Drawer>
      </>
    )
  );
}
