import { Divider, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import GoogleTab from "./GoogleTab";
import { useSelector } from "react-redux";
import { selectIsMicrosoftTeamsEnabled } from "../../../../session/slice";
import MicrosoftTab from "./MicrosoftTab";
import WhatsAppTab from "./WhatsAppTab";
import ZoomTab from "./ZoomTab";
import __ from "../../../../../app/i18n";
import { AccessControl } from "../../../../permissions/AccessControl";
import { PERMISSIONS } from "../../../../../app/utils";
import { selectGoogleData } from "./slices/googleSlice";
import { selectMicrosoftData } from "./slices/microsoftSlice";
import Api from "../../../../../app/api";
import { useConnectedCalendarsCount } from "../../../../../hooks/calendars/useConnectedCalendarsCount";

export const IntegrationsTab = () => {
  const {googleData, ready: googleIsReady} = useSelector(selectGoogleData);
  const {microsoftData, ready: microsoftIsReady} = useSelector(selectMicrosoftData);
  const isMicrosoftTeamsEnabled = useSelector(selectIsMicrosoftTeamsEnabled);
  const connectedCalendarsCount = useConnectedCalendarsCount();
  const [seats, setSeats] = useState(null);
  const [isLoadingLicensesData, setIsLoadingLicensesData] = useState(true);

  useEffect(() => {
    Api("googleConnections", {}).then((data) => {
            setSeats(data.seats);
          });
  }, [])

  useEffect(() => {
    if (seats !== null) {
      setIsLoadingLicensesData(false);
    }
  }, [seats]);

  const canConnectCalendar = useMemo(() => {
    return seats !== null && seats > connectedCalendarsCount
  }, [seats, connectedCalendarsCount]);

  return (
    <div>
      <AccessControl permission={PERMISSIONS.CALENDAR.CONNECT} condition={googleIsReady && googleData.isLoggedIn}>
        <Typography.Title level={5}>Google Calendar</Typography.Title>
        <GoogleTab canConnectCalendar={canConnectCalendar} isLoadingLicensesData={isLoadingLicensesData} />
        <Divider />
      </AccessControl>
      {isMicrosoftTeamsEnabled ? (
        <AccessControl permission={PERMISSIONS.CALENDAR.CONNECT} condition={microsoftIsReady && microsoftData.isLoggedIn}>
          <li>
            <Typography.Title level={5}>Microsoft Outlook</Typography.Title>
            <MicrosoftTab canConnectCalendar={canConnectCalendar} isLoadingLicensesData={isLoadingLicensesData} />
          </li>
          <Divider />
        </AccessControl>
      ) : (
        ""
      )}

      {/* <Divider /> */}
      {/* <div>
        <Typography.Title level={5}>Zoom</Typography.Title>
        <ZoomTab/>
      </div> */}
      <AccessControl permission={PERMISSIONS.INTEGRATIONS.CONNECT_WHATSAPP}>
        <Typography.Title level={5}>WhatsApp</Typography.Title>
        <WhatsAppTab />
      </AccessControl>
    </div>
  );
};
