import { useDispatch, useSelector } from "react-redux";
import { loadUsers, selectUsers } from "../team/users/slice";
import { useEffect, useState, useMemo } from "react";
import { message, Modal, Select, Space } from "antd";
import { updatePrivacyMeeting } from "../library/slice";
import { loadRoles, selectRoles } from "../team/roles/slice";
import { useTranslation } from "react-i18next";

const ALL_ROLES_OPTION = { value: "ALL", label: "Todos" }

const PrivacyModal = ({ meeting, open, onClose }) => {
  const { t } = useTranslation();
  const { visibility } = meeting;
  const dispatch = useDispatch();
  const roles = useSelector(selectRoles)
  const users = useSelector(selectUsers);
  const [fetched, setFetched] = useState();
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if(roles?.length) {
      const options = roles.map(role => ({
        value: role._id,
        label: role.name
      }));
      setOptions([ALL_ROLES_OPTION, ...options]);
      setOptionsSelected(visibility.length ? visibility.map(role => ({ value: role })) : [ALL_ROLES_OPTION.value]);
    }
      
  }, [roles, visibility])
  

  useEffect(() => {
    if (!users.length && !fetched) {
      dispatch(loadUsers());
      setFetched(true);
    }
    if(!roles?.length) {
      dispatch(loadRoles());
    }
  }, [users, fetched, roles?.length, dispatch]);


  const selectProps = {
    style: {
      width: "100%",
      marginRight: "5px",
    },
    value: optionsSelected,
    options: options,
    onChange: (newValues) => {
      if (newValues.includes(ALL_ROLES_OPTION.value) && newValues.length > 1) {
        message.error(t("generic.errors.roles_cannot_select_all_and_others"));
        setOptionsSelected([ALL_ROLES_OPTION.value]);
      } else {
        setOptionsSelected(newValues);
      }
    },
    mode: "multiple",
    maxTagCount: "responsive",
    showArrow: true,
  };

  const handleSaveChanges = () => {
    if(optionsSelected.length === 0) {
      message.error(t("generic.errors.no_roles_selected"));
      return;
    }

    const payload = {
      id: meeting.id,
      access: optionsSelected[0].value === "ALL" ? [] : optionsSelected.map(option => option),
    };
    dispatch(updatePrivacyMeeting(payload));
    onClose();
  };

  return (
    <>
    <Modal
      title={t("myNextMeetings.table.columns.privacy.modal.title")}
      centered
      open={open}
      onOk={() => handleSaveChanges()}
      okText={t("library.saveChanges")}
      onCancel={onClose}
    >
      <>
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <div>
            <Select {...selectProps} />
          </div>
          <br />
        </Space>
      </>
    </Modal>
    </>
  );
};

export default PrivacyModal;
