import { Button, Select } from "antd";
import { useSelector } from "react-redux";
import { Download } from "tabler-icons-react";
import Api from "../../app/api";
import { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { firstUp } from "../meetings/Item";
import { selectInteractionCallTypes } from "../team/interactionCallType/slice";
import { selectCoachingAppCreatedAt } from "../session/slice";
import { useTranslation } from "react-i18next";

const DownloadMonthlyReport = () => {
  const [months, setMonths] = useState([]);
  const [callType, setCallType] = useState();
  const [downloading, setDownloading] = useState(false);

  const createdAt = useSelector(selectCoachingAppCreatedAt);
  const [currentMonth, setCurrentMonth] = useState(
    moment().subtract(1, "month").startOf("month").format("YYYY-MM")
  );

  const { t } = useTranslation();

  const callTypes = useSelector(selectInteractionCallTypes);
  const options = useMemo(
    () =>
      (callTypes || []).map((callType) => ({
        label: callType.name,
        value: callType._id,
      })),
    [callTypes]
  );

  useEffect(() => {
    const render = () => {
      const map = new Map();
      let from = moment(createdAt).startOf("month");
      while (from.isBefore(moment().endOf("month"))) {
        const label =
          firstUp(from.format("MMMM").replace("º", "")) +
          " " +
          from.format("YYYY");
        const index = from.format("YYYY-MM");

        map.set(index, { key: index, value: index, label });
        from.add(1, "month");
      }

      setMonths(Array.from(map.values()).reverse());
    };

    if (createdAt) {
      return render();
    }
  }, [createdAt]);

  const handleMonthChange = (value) => {
    const selectedDate = moment(value).startOf("month").format("YYYY-MM"); // Formatear a la estructura deseada
    setCurrentMonth(selectedDate);
  };

  const handleCallTypeChange = (value) => {
    setCallType(value);
  };

  async function handleDownload() {
    setDownloading(true);
    const response = await Api("getCoachingReport", {
      date: currentMonth,
      callType,
    });

    if (response && response.reportUrl) {
      document.location.href = response.reportUrl;
    }
    setDownloading(false);
  }

  return (
    <>
      <Select
        style={{ width: "150px", margin: "5px" }}
        value={currentMonth}
        onChange={handleMonthChange}
      >
        {months.map((month) => (
          <Select.Option key={month.key} value={month.value}>
            {month.label}
          </Select.Option>
        ))}
      </Select>

      <Select
        allowClear
        options={options}
        onChange={handleCallTypeChange}
        style={{ width: "150px", margin: "5px" }}
        placeholder={t("callType.selectCallType")}
        optionFilterProp="label"
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
      />

      <Button
        icon={<Download size={12} />}
        disabled={!callType || !currentMonth || downloading}
        onClick={handleDownload}
      >
        <span style={{ marginLeft: "5px" }}>
          {t("reports.downloadMonthlyReport")}
        </span>
      </Button>
    </>
  );
};

export default DownloadMonthlyReport;
