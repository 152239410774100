import { useSelector } from "react-redux";
import { selectUser } from "../../features/session/slice";
import { hasPermission } from "./utils";

export const useUserHasPermission = (permissions, requireAll = true) => {
  const user = useSelector(selectUser);
  if (!user) return false;

  return hasPermission(user, permissions, requireAll);
};
