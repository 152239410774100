import { useMemo } from "react";
import { useRecordingRoles } from "./useRecordingRoles";
import { useSelector } from "react-redux";
import { selectUsers } from "../../features/team/users/slice";

export const useUsersWhoCanRecord = () => {
  const { recordingRoles } = useRecordingRoles();
  const users = useSelector(selectUsers);

  const usersWhoCanRecord = useMemo(() => {
    const usersWhoCanRecord = users.filter((user) => {
      return user.access.some((roleId) =>
        recordingRoles.some((role) => role._id === roleId)
      );
    });
    return usersWhoCanRecord;
  }, [users, recordingRoles]);

  return usersWhoCanRecord;
};
