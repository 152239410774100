import { Table } from "antd";
import { useSelector } from "react-redux";
import {
  selectCallTypeId,
  selectCoachingData,
  selectIsLoading,
  selectTeamId,
} from "./slice";
import styles from "./coaching.module.css";
import VendorCell from "./VendorCell";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function CoachingTable() {
  const { t } = useTranslation();
  const data = useSelector(selectCoachingData);
  const loading = useSelector(selectIsLoading);
  const teamId = useSelector(selectTeamId);
  const callTypeId = useSelector(selectCallTypeId);

  const [showTeams, setShowTeams] = useState(false);
  // if there're any team in the report, we show teams instead of users
  useEffect(() => {
    setShowTeams(
      (data || []).filter(({ entityType }) => entityType === "team").length > 1
    );
  }, [data]);

  const getColumns = () => {
    const columns = [
      {
        title: showTeams ? t("coaching.team") : t("coaching.seller"),
        dataIndex: "name",
        key: "name",
        fixed: "left",
        width: 180,
        render: (text, record) => {
          if (!showTeams) {
            return (
              <VendorCell
                name={record.name}
                email={record.email}
                avatarUrl={record.avatarUrl}
              />
            );
          }
          return text;
        },
      },
      {
        title: t("coaching.total"),
        dataIndex: "total",
        key: "total",
        width: 150,
        sorter: (a, b) => a.total - b.total,
      },
      {
        title: t("coaching.average"),
        dataIndex: "score",
        key: "score",
        width: 120,
        render: (score) => `${score?.toFixed(1)}%` || "0%",
        sorter: (a, b) => a.score - b.score,
      },
    ];

    // solo agrego categorias si el calltype esta seleccionado
    // no tiene sentido sino agrupar reglas de distinto tipo de llamadas
    if (!callTypeId) {
      return columns;
    }

    const categoriesColumns = new Set();
    data.forEach(({ categories }) => {
      categories.forEach((category) => {
        categoriesColumns.add(category.categoryName);
        category.items.forEach((item) => {
          categoriesColumns.add(item.evaluable);
        });
      });
    });

    Array.from(categoriesColumns).forEach((categoryName) => {
      columns.push({
        title: categoryName,
        dataIndex: categoryName,
        key: categoryName,
        width: 150,
        render: (score) =>
          score !== undefined && score !== null ? `${score.toFixed(1)}%` : "-",
        sorter: (a, b) => {
          const scoreA = a.score?.[categoryName] || 0;
          const scoreB = b.score?.[categoryName] || 0;
          return scoreA - scoreB;
        },
      });
    });

    return columns;
  };

  const processData = () => {
    const rows = data
      .filter(({ entityType }) =>
        showTeams ? entityType === "team" : entityType === "user"
      )
      .map((data) => {
        const row = { ...data };
        data.categories.forEach((category) => {
          row[category.categoryName] = category.score;
          category.items.forEach((item) => {
            row[item.evaluable] = item.score;
          });
        });
        return row;
      });

    return rows;
  };

  return (
    <div className={styles.tableContainer}>
      <Table
        columns={getColumns()}
        dataSource={processData()}
        loading={loading}
        scroll={{ x: "max-content" }}
        pagination={false}
        className={styles.coachingTable}
      />
    </div>
  );
}
