// src/features/team/interactionCallType/index.js
import React, { useEffect, useMemo, useState } from "react";
import {
  Layout,
  Typography,
  Table,
  Button,
  Input,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import __ from "../../../app/i18n";
import styles from "../team.module.css";
import NewCallType from "./NewCallType";
import EditCallType from "./editCallType";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  selectInteractionCallTypes,
  selectIsFetchingInteraction,
  deleteInteractionCallType,
  loadInteractionCallTypes,
} from "./slice";
import { loadTeams, selectTeams } from "../teams/slice";
import { PERMISSIONS, removeAccents } from "../../../app/utils";
import { AccessControl } from "../../permissions/AccessControl";
import { useUserHasPermission } from "../../../hooks/permissions/useUserHasPermission";

import { useTranslation } from "react-i18next";
const { Content } = Layout;
const { Paragraph } = Typography;

const SECTIONS = {
  NEW_CALLTYPE: "newCallType",
  EDIT_CALLTYPE: "editCallType",
};

export default function ViewCallType() {
  const dispatch = useDispatch();
  const hasAnyCallTypesPermission = useUserHasPermission([PERMISSIONS.CALL_TYPES.UPDATE, PERMISSIONS.CALL_TYPES.DELETE], false);
  const teams = useSelector(selectTeams);
  const callTypesIsLoading = useSelector(selectIsFetchingInteraction);
  const callTypes = useSelector(selectInteractionCallTypes);
  const [section, setSection] = useState("");
  const [callTypeForEdit, setCallTypeForEdit] = useState();
  const [searchText, setSearchText] = useState("");
  const [filteredCallTypes, setFilteredCallTypes] = useState(callTypes);
  const { t } = useTranslation();

  const handleDeleteCallType = (id) => {
    dispatch(deleteInteractionCallType({ id }));
  };

  const handleEditCallType = (callType) => {
    setCallTypeForEdit(callType);
    setSection(SECTIONS.EDIT_CALLTYPE);
  };

  const handleSearch = (e) => {
    const value = removeAccents(e.target.value.toLowerCase().trim());
    setSearchText(value);

    const filteredData = callTypes.filter((callType) =>
      removeAccents(callType.name.toLowerCase()).includes(value)
    );
    setFilteredCallTypes(filteredData);
  };

  useEffect(() => {
    dispatch(loadTeams());
    dispatch(loadInteractionCallTypes());
  }, [dispatch]);
  

  useEffect(() => setFilteredCallTypes(callTypes), [callTypes]);

  const columns = useMemo(() => [
    {
      title: t("team.callTypes.table.columns.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("team.callTypes.table.columns.teams"),
      key: "teams",
      width: 100,
      renderx: (_, user) => {
        const userTeams = teams
          .filter((team) => team.users.includes(user.id))
          .sort((a, b) => (a.name < b.name ? -1 : 1));

        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {userTeams.map((team) => (
              <Tag key={team.id} color={team.color}>
                {team.name}
              </Tag>
            ))}
          </div>
        );
      },
      render: (text, callType) => {
        if (callType.teams.length === teams.length) {
          return t("generic.labels.all");
        }

        if (callType.teams.length === 0) {
          return "Sin asignar";
        }

        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {callType?.teams?.length &&
              callType.teams.map((teamId) => {
                const team = teams.find((team) => team._id === teamId);

                return (
                  <Tag key={team.id} color={team.color}>
                    {team.name}
                  </Tag>
                );
              })}{" "}
          </div>
        );
      },
      filters: teams.map((team) => ({
        text: (
          <Tag key={team._id} color={team.color}>
            {team.name}
          </Tag>
        ),
        value: team._id,
      })),
      onFilter: (teamId, callType) => {
        return Array.isArray(callType.teams) && callType.teams.includes(teamId);
      },
    },
    {
      title: t("team.callTypes.table.columns.description"),
      dataIndex: "description",
      key: "description",
      width: 300,
      render: (text) => {
        return text ? (
          <Paragraph ellipsis={{ rows: '2' }}>
            {text}
          </Paragraph>
        ) : (
          "Sin descripción"
        );
      },
    },
    hasAnyCallTypesPermission && {
      title: "",
      key: "actions",
      render: (_, callType) => (
        <div style={{ display: "flex" }}>
          <AccessControl permission={PERMISSIONS.CALL_TYPES.UPDATE}>
            <EditOutlined
              style={{ fontSize: "20px" }}
              onClick={() => handleEditCallType(callType)}
            />
          </AccessControl>
          <AccessControl permission={PERMISSIONS.CALL_TYPES.DELETE}>
            <DeleteOutlined
              style={{ marginLeft: ".5rem", fontSize: "20px", color: "red" }}
              onClick={() => handleDeleteCallType(callType._id)}
            />
          </AccessControl>
        </div>
      ),
    },
  ].filter(Boolean), [handleDeleteCallType, hasAnyCallTypesPermission, teams]);

  return (
    <Content>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "2rem",
        }}
      >
        <Typography.Title level={3} style={{ fontWeight: 400 }}>
          {__("team.callTypes.label")}
        </Typography.Title>
        <span className={styles.topRightSection}>
          <Input
            placeholder={__("team.list.searchByName")}
            value={searchText}
            onChange={handleSearch}
            style={{ width: 300, marginRight: "15px" }}
            prefix={<SearchOutlined />}
            allowClear
          />
          <AccessControl permission={PERMISSIONS.CALL_TYPES.CREATE}>
            <Button
                onClick={() => setSection(SECTIONS.NEW_CALLTYPE)}
                type="primary"
                className={styles.addTeamCircle}
              >
                +
              </Button>
          </AccessControl>
        </span>
      </div>
      {callTypesIsLoading && (
        <Table columns={columns} dataSource={filteredCallTypes} size="middle" />
      )}
      <AccessControl permission={PERMISSIONS.CALL_TYPES.CREATE}>
        <NewCallType
          open={section === SECTIONS.NEW_CALLTYPE}
          onClose={() => setSection("")}
        />
      </AccessControl>
      <AccessControl permission={PERMISSIONS.CALL_TYPES.UPDATE}>
      <EditCallType
          open={section === SECTIONS.EDIT_CALLTYPE}
          callType={callTypeForEdit}
          onClose={() => {
            setSection("");
            setCallTypeForEdit(null);
          }}
        />
      </AccessControl>
    </Content>
  );
}
