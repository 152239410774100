import { Alert, Avatar, Button, Divider, List, Typography } from "antd";
import Section from "../../meeting/sections/Section";
import {
  IconBriefcase,
  IconCalendarMonth,
  IconCoin,
  IconRoute,
  IconArrowRight,
} from "@tabler/icons-react";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Deal = (data) => {
  let deal = { ...data };
  let currentDealData = { ...data.currentDealData };
  const { t } = useTranslation();

  const props = Object.keys(deal).filter((d) =>
    ["amount", "dealStageName", "closedate", "dealname"].includes(d)
  );

  const icons = {
    dealname: <IconBriefcase />,
    dealStageName: <IconRoute />,
    closedate: <IconCalendarMonth />,
    amount: <IconCoin />,
  };

  if (data.closedate) {
    deal.closedate =
      moment(data.closedate).format("Do").replace("º", "") +
      " de " +
      moment(data.closedate).format("MMMM") +
      " del " +
      moment(data.closedate).format("YYYY");
  } else {
    deal.closedate = " - ";
  }

  if (currentDealData.closedate) {
    currentDealData.closedate =
      moment(currentDealData.closedate).format("Do").replace("º", "") +
      " de " +
      moment(currentDealData.closedate).format("MMMM") +
      " del " +
      moment(currentDealData.closedate).format("YYYY");
  } else {
    currentDealData.closedate = " - ";
  }

  return (
    <>
      {props.length ? (
        <Alert
          message={
            <List
              itemLayout="horizontal"
              dataSource={[1]}
              renderItem={(k) => (
                <List.Item>
                  <List.Item.Meta />
                  <List.Item.Meta
                    description={
                      <Typography.Title level={5} type="secondary">
                        {t(
                          "meetings.details.sections.apps.crm.generic.beginOfMeeting"
                        )}
                      </Typography.Title>
                    }
                  />
                  <List.Item.Meta
                    style={{ textAlign: "center" }}
                    description={<IconArrowRight />}
                  />
                  <List.Item.Meta
                    description={
                      <Typography.Title level={5} type="secondary">
                        {t(
                          "meetings.details.sections.apps.crm.generic.currently"
                        )}
                      </Typography.Title>
                    }
                  />
                </List.Item>
              )}
            />
          }
          type="info"
        />
      ) : (
        ""
      )}

      <List
        itemLayout="horizontal"
        dataSource={props}
        renderItem={(k) => (
          <List.Item>
            <List.Item.Meta
              avatar={icons[k]}
              description={t("apps.hubspot.tab.properties." + k)}
            />
            <List.Item.Meta
              title={
                k === "amount"
                  ? "$" + (deal[k] ? deal[k] : "-")
                  : deal[k] || "-"
              }
            />
            <List.Item.Meta
              style={{ textAlign: "center" }}
              description={<IconArrowRight />}
            />
            <List.Item.Meta
              title={
                k === "amount"
                  ? "$" + (currentDealData[k] ? currentDealData[k] : "-")
                  : currentDealData[k] || "-"
              }
            />
          </List.Item>
        )}
      />
      {props && props.length ? (
        <a
          href={`https://app.hubspot.com/contacts/${data.portalId}/record/0-3/${data.id}`}
          target="blank"
        >
          <Button type="primary" style={{ width: "100%" }}>
            {t("meetings.details.sections.apps.crm.hubspot.see")}
          </Button>
        </a>
      ) : (
        ""
      )}
    </>
  );
};

export default function Tab({ data }) {
  const { t } = useTranslation();
  const deal = data.properties?.deal || [];
  // const contacts = data.properties?.contacts || [];

  return (
    <Section key={data.id} sectionKey={data.id} title={t(data.name)}>
      {deal ? (
        <Deal
          {...deal}
          currentDealData={data?.properties?.currentDealData}
          portalId={data.portalId}
        />
      ) : (
        t("noInformation")
      )}
      <Divider />
    </Section>
  );
}
