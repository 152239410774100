import { Typography, Card, Row, Col, Tag, Statistic } from "antd";
import { useSelector } from "react-redux";
import styles from "./organization.module.css";
import { selectUser } from "../../session/slice";
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { selectAccount } from "./slice";
import { useTranslation } from "react-i18next";

export default function PlanTab() {
  const user = useSelector(selectUser);
  const account = useSelector(selectAccount);
  const { t } = useTranslation();
  const getPlanStatus = () => {
    if (account?.billing?.status === "paying") {
      return {
        status: "active",
        color: "success",
        icon: <CheckCircleOutlined />,
        text: t("team.organization.plan.active"),
      };
    }
    return {
      status: "trial",
      color: "warning",
      icon: <ClockCircleOutlined />,
      text: t("team.organization.plan.trial"),
    };
  };

  const planStatus = getPlanStatus();

  return (
    <div className={styles.formContainer}>
      <Typography.Title level={4} className={styles.formTitle}>
        {t("team.organization.plan.title")}
      </Typography.Title>

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <Typography.Text strong style={{ marginRight: 8 }}>
              {t("team.organization.plan.status")}:
            </Typography.Text>
            <Tag icon={planStatus.icon} color={planStatus.color}>
              {planStatus.text}
            </Tag>
          </div>
        </Col>

        {account?.billing?.status === "trial" && (
          <Col span={12}>
            <Card bordered={false} style={{ background: "#fffbe6" }}>
              <Statistic
                title={t("team.organization.plan.trialDaysLeft")}
                value={user.trialDaysLeft}
                suffix={t("team.organization.plan.days")}
                valueStyle={{ color: "#d48806" }}
                prefix={<ClockCircleOutlined />}
              />
            </Card>
          </Col>
        )}

        <Col span={12}>
          <Card bordered={false} style={{ background: "#f6ffed" }}>
            <Statistic
              title={t("team.organization.plan.licenses")}
              value={account?.seats ?? 0}
              prefix={<TeamOutlined />}
              valueStyle={{ color: "#52c41a" }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
