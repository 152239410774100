import { useEffect } from "react";
import { Form, Input, Button, Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateAccount } from "./slice";
import styles from "./organization.module.css";
import { selectAccount } from "./slice";
import { useTranslation } from "react-i18next";
import { useUserHasPermission } from "../../../hooks/permissions/useUserHasPermission";
import { PERMISSIONS } from "../../../app/utils";

const { TextArea } = Input;

export default function InformationTab() {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const hasOrganizatinoUpdate = useUserHasPermission(
    PERMISSIONS.ACCOUNT.UPDATE
  );

  useEffect(() => {
    form.setFieldsValue({
      displayName: account?.displayName,
      about: account?.about,
      website: account?.website,
      competitors: account?.competitors,
    });
  }, [account]);

  const onFinish = (values) => {
    try {
      dispatch(updateAccount(values));
      message.success(t("team.organization.messages.updateSuccess"));
    } catch (error) {
      message.error(t("team.organization.messages.updateError"));
    }
  };
  return (
    <div className={styles.formContainer}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className={styles.formSection}>
          <Form.Item
            name="displayName"
            label={t("team.organization.accountName")}
            rules={[
              { required: true, message: t("team.organization.nameRequired") },
            ]}
          >
            <Input disabled={!hasOrganizatinoUpdate} />
          </Form.Item>

          <Form.Item name="about" label={t("team.organization.about")}>
            <TextArea rows={4} disabled={!hasOrganizatinoUpdate} />
          </Form.Item>

          <Form.Item
            name="website"
            label={t("team.organization.website")}
            rules={[
              {
                type: "url",
                message: t("team.organization.invalidWebsite"),
              },
            ]}
          >
            <Input placeholder="https://" disabled={!hasOrganizatinoUpdate} />
          </Form.Item>

          <Form.Item
            name="competitors"
            label={t("team.organization.competitors")}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder={t("team.organization.competitorsPlaceholder")}
              tokenSeparators={[","]}
              open={false}
              allowClear
              disabled={!hasOrganizatinoUpdate}
            />
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!hasOrganizatinoUpdate}
          >
            {t("team.organization.save")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
