import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../../app/api";
import { showGlobalErrors } from "../../loading/slice";

const initialState = {
  status: "loading",
  permissions: [],
  selectedPermissionIds: [],
  fetched: false,
};

export const loadPermissions = createAsyncThunk(
  "permissions/loadPermissions",
  async (payload, { dispatch }) => {
    const response = await Api("getPermissions");
    
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

export const permissionSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = "loading";
    },
    stopLoading: (state) => {
      state.status = "idle";
    },
    setSelectedPermissionIds: (state, action) => {
      state.selectedPermissionIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadPermissions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadPermissions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.permissions = action.payload;
        state.fetched = true;
      })
  },
});

export const { startLoading, stopLoading, setSelectedPermissionIds } = permissionSlice.actions;

export const selectPermissions = (state) => state.permissions.permissions;
export const selectIsFetchingPermissions = (state) => state.permissions.status === 'idle';
export const selectPermissionsFetched = (state) => state.permissions.fetched;
export const selectSelectedPermissionIds = (state) => state.permissions.selectedPermissionIds;


export default permissionSlice.reducer;
