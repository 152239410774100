import { Tooltip, Alert, Layout, Menu, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  getConfig,
  logout,
  selectIsCoachingEnabled,
  selectUser,
} from "./slice";
import { useDispatch, useSelector } from "react-redux";
import styles from "./LoggedIn.module.css";
import NotificationMenu from "../notifications/NotificationMenu";
import logo from "../../brand/Isologotype/samu_white_dark_bckgrnd.png";
import { useEffect, useState } from "react";
import { SettingOutlined, CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { analytics } from "../../hooks/tracker";
import { Content } from "antd/lib/layout/layout";
import { PERMISSIONS } from "../../app/utils";
import { usePermissionStatus } from "../../hooks/permissions/usePermissionStatus";
import { InfoCircle } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { useUserHasPermission } from "../../hooks/permissions/useUserHasPermission";
const { Header, Footer } = Layout;

const LoggedIn = ({ children }) => {
  const user = useSelector(selectUser);
  const hasSeeAnalyticsView = useUserHasPermission([
    PERMISSIONS.ANALYTICS.VIEW,
    PERMISSIONS.ANALYTICS.DOWNLOAD_REPORT
  ], false)
  
  const userPermissionsStatus = usePermissionStatus([
    PERMISSIONS.ANALYTICS.VIEW,
    PERMISSIONS.CALENDAR.MY_AGENDA,
    PERMISSIONS.CALENDAR.TEAM_AGENDA,
    PERMISSIONS.APPS.MANAGE,
    PERMISSIONS.COACHING.VIEW
  ])
  
  const isCoachingEnabled = useSelector(selectIsCoachingEnabled);
  const dispatch = useDispatch();
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getConfig());
  }, []);

  useEffect(() => {
    if (user && analytics) {
      analytics.identify(user._id, {
        firstName: user.name,
        lastName: user.lastName,
        email: user.email,
        avatar: user.image,
        accountId: user.account,
        role: user.role,
      });
    }
  }, [user]);


  const handleLogout = () => {
    dispatch(logout());
  };

  const initials = (name) => {
    const initials = name
      .split(" ")
      .map((w) => w.charAt(0))
      .join("");

    return <span className={styles.initialsBuble}>{initials}</span>;
  };

  const userIcon = (user) => {
    return initials(user.name);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {user?.isBillingActive && user?.trialDaysLeft <= 7 && user?.trialDaysLeft > 0 && (
        <Alert
          message={t("generic.alerts.trialEnding.title")}
          description={t("generic.alerts.trialEnding.description1") + user.trialDaysLeft + t("generic.alerts.trialEnding.description2")}
          type="warning"
          showIcon
          banner
        />
      )}
      <Header className="header" style={{position: 'relative'}}>
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={[]}
          triggerSubMenuAction="click"
        >
          <Menu.Item
            key="logo"
            style={{ fontFamily: "secular" }}
            className={styles.logoMenuItem}
          >
            <Link to="meetings">
              <img src={logo} alt={t("appName")} style={{ width: "135px" }} />
            </Link>
          </Menu.Item>
          <Menu.Item key="meetings">
            <Link to="meetings">{t("meetings.label")}</Link>
          </Menu.Item>
          {
            hasSeeAnalyticsView && (
              <Menu.Item key="analytics">
                <Link to="analytics">{t("analytics.label")}</Link>
              </Menu.Item>
          )}
          <Menu.Item key="library">
            <Link to="library">{t("library.label")}</Link>
          </Menu.Item>
          {
            (userPermissionsStatus[PERMISSIONS.CALENDAR.MY_AGENDA] || userPermissionsStatus[PERMISSIONS.CALENDAR.TEAM_AGENDA]) && (
              <Menu.Item key="agenda">
                <Link to="agenda">{t("agenda.label")}</Link>
              </Menu.Item>
          )}
          {
            userPermissionsStatus[PERMISSIONS.APPS.MANAGE] && (
              <Menu.Item key="apps">
                <Link to="apps">{t("apps.label")}</Link>
              </Menu.Item>
          )}
          {userPermissionsStatus[PERMISSIONS.COACHING.VIEW] && (
              <Menu.Item key="coaching">
                <Link to="coaching">{t("coaching.label")}</Link>
              </Menu.Item>
          )}
          <span
            style={{
              position: "absolute",
              right: "175px",
              top: "4px",
              zIndex: 998,
              cursor: "pointer",
            }}
          >
            <Tooltip title={t("team.tooltip")}>
              <SettingOutlined
                style={{
                fontSize: "22px",
                color: "#fff",
                marginRight: "12px",
                marginBottom: "-3px",
                }}
                onClick={() => navigate("/settings")} />
            </Tooltip>
            <NotificationMenu />
          </span>
          <span
            style={{
              position: "absolute",
              right: "315px",
              top: "4px",
              zIndex: 998,
              cursor: "pointer",
            }}
          >
            <Tooltip title={t("menu.help")}>
              <InfoCircle
                size={'25px'}
                style={{
                  fontSize: "25px",
                  color: "#fff",
                  marginBottom: "-5px",
                }}
                onClick={() => window.open('https://samu.crunch.help/')} />
            </Tooltip>
          </span>

          <Menu.Item
            key="account"
            style={{ position: "absolute", right: 0 }}
            onClick={() => setSubmenuOpen((v) => !v)}
          >
            <Menu.SubMenu
              key="SubMenu"
              title={
                <>
                  <>{user && user.name} </>
                  {isSubmenuOpen ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )}{" "}
                </>
              }
              icon={userIcon(user)}
            >
              <Menu.Item key="profile">
                <Link to="settings">{t("team.settings")}</Link>
              </Menu.Item>

              <Menu.Item key="my-next-meetings">
                <Link to="scheduled">{t("myNextMeetings.label")}</Link>
              </Menu.Item>

              {/* <ManagerComponent> // No existe mas el componente ManagerComponent
                <Menu.Item key="company-settings">
                  <Link to="settings">{t("menu.settings")}</Link>
                </Menu.Item>
              </ManagerComponent> */}

              <Menu.Item key="two" onClick={handleLogout}>
                {t("menu.logout")}
              </Menu.Item>
            </Menu.SubMenu>
          </Menu.Item>
        </Menu>
      </Header>
      <Content>{children}</Content>
      <Footer
        style={{
          textAlign: "center",
          background: "#1791ff",
          color: "#fff",
          borderTop: "1px solid #ddd",
          zIndex: 3,
        }}
      >
        {t("appName")} © {new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

export default LoggedIn;
