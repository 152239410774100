import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../app/api";

export const updateAccount = createAsyncThunk(
  "organization/update",
  async (data) => {
    const response = await api("accountUpdate", data);
    if (response.error) {
      throw new Error(response.error);
    }
    return response;
  }
);

export const generateApiKey = createAsyncThunk(
  "organization/generateApiKey",
  async (data) => {
    const response = await api("generateApiKey", data);
    if (response.error) {
      throw new Error(response.error);
    }
    return response;
  }
);

export const getAccount = createAsyncThunk("organization/get", async () => {
  const response = await api("accountMe");
  return response;
});
const accountInfoSlice = createSlice({
  name: "organization",
  initialState: {
    seats: 0,
    account: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccount.fulfilled, (state, action) => {
      state.account = action.payload;
    });
  },
});

export const selectAccount = (state) => state.organization.account;

export default accountInfoSlice.reducer;
