import { Progress, Typography, message } from "antd";
import styles from "./index.module.css";
import { useMemo, useState, useEffect } from "react";
import { SamuScoreModal } from "./Modal";
import { useSelector } from "react-redux";
import { selectUser } from "../../session/slice";
import { CustomSamuScoreModal } from "./CustomSamuScore/CustomSamuScoreModal";
import { PERMISSIONS } from "../../../app/utils";
import { useUserHasPermission } from "../../../hooks/permissions/useUserHasPermission";
import { useTranslation } from "react-i18next";
import Api from "../../../app/api";
import __ from "../../../app/i18n";
import { selectInteractionCallTypes } from "../../team/interactionCallType/slice";
import { analytics } from "../../../hooks/tracker";

export const SamuScoreItem = ({ meeting, size }) => {
  const user = useSelector(selectUser);
  const interactionCallTypes = useSelector(selectInteractionCallTypes);
  const { t } = useTranslation();
  const canSeeDetailsOfAnyUser = useUserHasPermission(
    PERMISSIONS.MEETINGS.SCORE_DETAILS
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [evaluables, setEvaluables] = useState(
    meeting?.customSamuScore?.evaluables
  );
  const [score, setScore] = useState(0);
  const isMyMeeting = useMemo(
    () => meeting.hostId === user._id,
    [meeting, user]
  );

  const [messageApi, contextHolder] = message.useMessage();
  const openModal = () => {
    setModalOpen(true);
    if (analytics) {
      const callTypeId =
        interactionCallTypes.find((item) => item.name === meeting.callType)
          ?._id ?? "UNQUALIFIED"; // agregar id de unqualified que esta en otro pr
      const analyticsData = {
        meetingId: meeting.id,
        callTypeId: callTypeId,
        callTypeName: meeting.callType ?? "UNQUALIFIED",
      };
      analytics.track("Custom Score Open Details", analyticsData);
    }
  };

  useEffect(() => {
    if (!meeting) {
      return;
    }

    if (meeting?.customSamuScore) {
      const scoreValue = Math.round(meeting.customSamuScore.score * 10) / 10;
      setScore(scoreValue);
    } else {
      setScore(
        Math.round((meeting?.samuScore || meeting?.customScore)?.total * 10) /
          10
      );
    }
  }, [meeting]);

  const seeDetailsComponent = useMemo(() => {
    if (canSeeDetailsOfAnyUser || isMyMeeting) {
      return (
        <a
          className={styles.subtitle}
          href="#"
          title="Ver detalles"
          onClick={openModal}
        >
          {t("generic.buttons.seeDetails")}
        </a>
      );
    }
    return <></>;
  }, [canSeeDetailsOfAnyUser, isMyMeeting, openModal, t]);
  if (!meeting.samuScore && !meeting.customScore && !meeting.customSamuScore) {
    return;
  }

  let color = "red";
  if (score > 4) {
    color = "yellow";
  }

  if (score > 6) {
    color = "#52c51a";
  }

  if (!size) {
    size = 70;
  }

  let title = "Samu Score";
  if (meeting.customScore) {
    title = (user.accountName || "Samu") + " score";
  }

  const handleUpdateMeetingCustomerScore = async (item) => {
    try {
      const response = await Api(
        "updateMeetingCustomerScore",
        {
          meetingId: meeting.id,
          item,
        },
        { revert: true }
      );

      setEvaluables(response.evaluables);
      setScore(response.score);

      messageApi.open({
        type: "success",
        content: t("messages.success"),
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: t("messages.error"),
      });
    }
  };

  return (
    <div
      className={styles.samuScoreItemContainer + " samu-score-item-container"}
    >
      {contextHolder}
      {meeting.customSamuScore ? (
        <CustomSamuScoreModal
          open={modalOpen}
          evaluables={evaluables}
          score={score}
          onClose={() => setModalOpen(false)}
          onUpdateMeetingCustomerScore={handleUpdateMeetingCustomerScore}
          hostId={meeting.hostId}
        />
      ) : (
        <SamuScoreModal
          open={modalOpen}
          meeting={meeting}
          onClose={() => setModalOpen(false)}
        />
      )}
      <Progress
        className={styles.progressContainer + " progressContainer"}
        percent={score}
        format={(number) => (
          <span className={styles.percentNumber}>{number}</span>
        )}
        success={{
          percent: score * 10,
          strokeColor: color,
        }}
        type="dashboard"
        width={size}
      />

      <Typography className={styles.title}>
        <b className={styles.subtitle}> {title}</b>
        {seeDetailsComponent}
      </Typography>
    </div>
  );
};
