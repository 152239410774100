import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../../app/api";
import { showGlobalErrors } from "../../loading/slice";

const initialState = {
  status: "loading",
  roles: [],
  fetched: false,
};

export const loadRoles = createAsyncThunk(
  "roles/loadRoles",
  async (payload, { dispatch }) => {
    const response = await Api("getRoles");
    
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

export const createRole = createAsyncThunk(
  "roles/create",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await Api("createRole", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());
    dispatch(loadRoles());

    return response;
  }
);

export const updateRole = createAsyncThunk(
  "roles/update",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await Api("updateRole", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());
    dispatch(loadRoles());

    return response;
  }
);

export const deleteRole = createAsyncThunk(
  "roles/delete",
  async (payload, { dispatch }) => {
    dispatch(startLoading());
    
    const response = await Api("deleteRole", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());
    dispatch(loadRoles());

    return response;
  }
);

export const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = "loading";
    },
    stopLoading: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.status = 'idle';
        state.roles = [...state.roles, action.payload];
      })
      .addCase(loadRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadRoles.fulfilled, (state, action) => {
        state.status = 'idle';
        state.roles = action.payload;
        state.fetched = true;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.status = 'idle';
        state.roles = state.roles.filter(role => role._id !== action.payload.id);
      });
  },
});

export const { startLoading, stopLoading } = roleSlice.actions;

export const selectRoles = (state) => state.roles.roles
export const selectIsFetchingRoles = (state) => state.roles.status === 'idle';
export const selectRolesFetched = (state) => state.roles.fetched;

export default roleSlice.reducer;
