import { Divider, List } from "antd";
import Section from "../../meeting/sections/Section";
import { fixGPTEncoding } from "../../../app/utils";
import { useTranslation } from "react-i18next";
export const prettyLabel = (label) =>
  decodeURIComponent(label).replace(/_/gi, " ");

const firstUp = (str) => {
  let result = "-";
  if (str) {
    try {
      if (str === "true") return "yes";
      if (str === "false") return "no";

      result = str[0].toUpperCase() + str.substring(1, str.length);
    } catch (error) {
      result = "-";
    }
  }

  return result;
};

const Properties = (data) => {
  data = Object.keys(data).map((key) => ({
    label: prettyLabel(key),
    value: data[key],
  }));

  const { t } = useTranslation();

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(k) => (
          <List.Item>
            <List.Item.Meta
              title={firstUp(t(k.label))}
              description={firstUp(t(fixGPTEncoding(String(k.value))))}
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default function Tab({ data }) {
  const { t } = useTranslation();
  const properties = data.properties;
  return (
    <Section key={data.id} sectionKey={data.id} title={t(data.name)}>
      {data ? <Properties {...properties} /> : t("noInformation")}
      <Divider />
    </Section>
  );
}
