import { Checkbox, Drawer, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPermissions,
  selectSelectedPermissionIds,
  setSelectedPermissionIds,
} from "../permissions/slice";
import { PermissionCategoryList } from "./PermissionCategoryList";
import { useEffect, useMemo } from "react";
import { groupPermissionsByCategory } from "./utils";
import { useTranslation } from "react-i18next";

export const RolePreview = ({ open, onClose, role }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const allPermissions = useSelector(selectPermissions);
  const selectedPermissions = useSelector(selectSelectedPermissionIds);

  const isAllSelected = useMemo(() => {
    return (
      selectedPermissions.length > 0 &&
      allPermissions.length > 0 &&
      allPermissions.every((permission) =>
        selectedPermissions.includes(permission._id)
      )
    );
  }, [selectedPermissions, allPermissions]);

  useEffect(() => {
    if (role) {
      dispatch(setSelectedPermissionIds(role.permissions.map((p) => p._id)));
    }
  }, [dispatch, role]);

  return (
    role &&
    allPermissions && (
      <>
        {contextHolder}
        <Drawer
          title={"Roles"}
          zIndex={999}
          placement={"right"}
          width={500}
          onClose={onClose}
          open={open}
          getContainer={true}
        >
          <Checkbox
            checked={isAllSelected}
            style={{ display: "flex", justifyContent:"right", marginBottom: "10px" }}
          >
            {t("generic.labels.all_permissions_selected")}
          </Checkbox>
          <PermissionCategoryList
            categories={groupPermissionsByCategory(
              allPermissions,
              role?.permissions
            )}
            isPreview={true}
          />
        </Drawer>
      </>
    )
  );
}
