import { Button, Checkbox, Drawer, Form, Input, message } from "antd";
import __ from "../../../app/i18n";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPermissions,
  selectSelectedPermissionIds,
  setSelectedPermissionIds,
} from "../permissions/slice";
import { PermissionCategoryList } from "./PermissionCategoryList";
import { useEffect, useMemo } from "react";
import { groupPermissionsByCategory } from "./utils";
import { createRole } from "./slice";
import styles from "./role.module.css";

export default function CreateRole({ open, onClose }) {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const allPermissions = useSelector(selectPermissions);
  const selectedPermissions = useSelector(selectSelectedPermissionIds);

  const [form] = Form.useForm();
  const handleSave = (values) => {
    if (selectedPermissions.length === 0) {
      messageApi.open({
        type: "error",
        content: __("generic.errors.no_permissions_selected"),
      });
      return;
    } else {
      dispatch(
        createRole({ name: values.roleName, permissions: selectedPermissions })
      );
      form.resetFields();
      dispatch(setSelectedPermissionIds([]));
      onClose();
    }
  };

  const isAllSelected = useMemo(() => {
    return (
      selectedPermissions.length > 0 &&
      allPermissions.length > 0 &&
      allPermissions.every((permission) =>
        selectedPermissions.includes(permission._id)
      )
    );
  }, [selectedPermissions, allPermissions]);

  const handleSelectAllPermissions = (e) => {
    if (e.target.checked) {
      dispatch(setSelectedPermissionIds(allPermissions.map((p) => p._id)));
    } else {
      dispatch(setSelectedPermissionIds([]));
    }
  };

  useEffect(() => {
    dispatch(setSelectedPermissionIds([]));
  }, [dispatch]);

  return (
    allPermissions && (
      <>
        {contextHolder}
        <Drawer
          title={"Roles"}
          zIndex={999}
          placement={"right"}
          width={500}
          onClose={onClose}
          open={open}
          getContainer={true}
        >
          <Form
            name="createRole"
            layout="vertical"
            onFinish={handleSave}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              name="roleName"
              label={__("generic.fields.role")}
              rules={[{ required: true, message: __("generic.required.role") }]}
              className={styles.formInput}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Checkbox
                checked={isAllSelected}
                onChange={handleSelectAllPermissions}
              >
                {__("generic.buttons.selectAll")}
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {__("meetings.filters.save")}
              </Button>
            </Form.Item>
            <PermissionCategoryList
              categories={groupPermissionsByCategory(allPermissions)}
              open={open}
            />
          </Form>
        </Drawer>
      </>
    )
  );
}
