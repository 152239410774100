import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../../../../../app/api";

export const fetchGoogleLoginData = createAsyncThunk(
  "google/fetchLoginData",
  async () => {
    const response = await Api("getGoogleLoginData");
    return response;
  }
);

export const disconnectGoogle = createAsyncThunk(
  "google/disconnect",
  async (_, { dispatch }) => {
    await Api("disconnectGoogle", {});
    dispatch(fetchGoogleLoginData());
  }
);

const initialState = {
  googleData: {},
  ready: false,
  loading: false,
  error: null,
};

const googleSlice = createSlice({
  name: "google",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoogleLoginData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGoogleLoginData.fulfilled, (state, action) => {
        state.googleData = action.payload;
        state.ready = true;
        state.loading = false;
      })
      .addCase(fetchGoogleLoginData.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const selectGoogleData = (state) => {
    return {
        googleData: state.googleCalendar.googleData,
        ready: state.googleCalendar.ready,
    }
};

export default googleSlice.reducer;
