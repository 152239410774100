import { Layout, Menu, Typography } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./team.module.css";
import { useTranslation } from "react-i18next";
import CallTypeView from "./interactionCallType/index";
import TeamsView from "./teams/index";
import OrganizationView from "./organization/index";
import { UsersView } from "./users/Index";
import { useDispatch, useSelector } from "react-redux";
import Profile from "./general/profile/Index";
import { NotificationsTab } from "./general/notifications/Index";
import { getConfig } from "../session/slice";
import { selectActiveTab, setActiveTab } from "./users/slice";
import { RolesView } from "./roles";
import { useUserHasPermission } from "../../hooks/permissions/useUserHasPermission";
import { PERMISSIONS } from "../../app/utils";
const { Content, Sider } = Layout;

const DEFAULT_SECTION_KEY = "profile";

export default function Team({ defaultSection = DEFAULT_SECTION_KEY }) {
  const dispatch = useDispatch();
  const hasSeeUsersView = useUserHasPermission([
    PERMISSIONS.USERS.LIST,
    PERMISSIONS.USERS.DEACTIVATE,
    PERMISSIONS.USERS.CREATE,
    PERMISSIONS.USERS.DELETE,
    PERMISSIONS.USERS.RESET_PASSWORD,
    PERMISSIONS.USERS.UPDATE,
  ], false);
  const hasOrganizationView = useUserHasPermission(PERMISSIONS.ACCOUNT.VIEW);
  const activeSection = useSelector(selectActiveTab);
  const { t } = useTranslation();
  document.title = `${t("appName")} - ${t("team.label")}`;
  const [collapsed] = useState(false);

  useEffect(() => {
    if (defaultSection) {
      dispatch(setActiveTab(defaultSection));
    }
    dispatch(getConfig());
  }, [dispatch, defaultSection]);

  const handleSectionChange = (section) => {
    dispatch(setActiveTab(section));
  };

  const renderContent = () => {
    switch (activeSection) {
      case "profile":
        return <Profile />;
      case "notifications":
        return <NotificationsTab />;
      case "users":
        return <UsersView />;
      case "callTypes":
        return <CallTypeView />;
      case "teams":
        return <TeamsView />;
      case "roles":
        return <RolesView />;
      case "account":
        return <OrganizationView />;
      default:
        return null;
    }
  };

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          width={250}
          collapsed={collapsed}
          style={{ background: "white" }}
        >
          <Typography className={styles.settingsTitle}>
            {t("team.settings")}
          </Typography>
          <Menu
            mode="inline"
            selectedKeys={[activeSection]}
            defaultOpenKeys={["preferences", "organization"]}
            style={{ borderRight: 0 }}
          >
            <Menu.SubMenu
              key="preferences"
              title={t("team.menu.preferences.label")}
            >
              <Menu.Item
                key="profile"
                onClick={() => handleSectionChange("profile")}
              >
                {t("team.menu.preferences.subMenu.general")}
              </Menu.Item>
              <Menu.Item
                key="notifications"
                onClick={() => handleSectionChange("notifications")}
              >
                {t("team.menu.preferences.subMenu.notifications")}
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="organization"
              title={t("team.menu.organization.label")}
            >
              {hasOrganizationView && (
                <Menu.Item
                  key="account"
                  onClick={() => handleSectionChange("account")}
                >
                  {t("team.generalInfo.title")}
                </Menu.Item>
              )}

              {hasSeeUsersView && (
                <Menu.Item
                  key="users"
                  onClick={() => handleSectionChange("users")}
                >
                  {t("team.users.label")}
                </Menu.Item>
              )}
              <Menu.Item
                key="teams"
                onClick={() => handleSectionChange("teams")}
              >
                {t("team.teams")}
              </Menu.Item>
              <Menu.Item
                key="calltypes"
                onClick={() => handleSectionChange("callTypes")}
              >
                {t("team.callTypes.label")}
              </Menu.Item>
              <Menu.Item
                key="roles"
                onClick={() => handleSectionChange("roles")}
              >
                {t("team.roles")}
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Sider>
        <Layout>
          <Content style={{ padding: "0 25px" }}>{renderContent()}</Content>
        </Layout>
      </Layout>
    </>
  );
}
