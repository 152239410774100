import { Tabs, Layout, Typography } from "antd";
import { useSelector } from "react-redux";
import * as primarySlice from "./slice";
import * as secondarySlice from "./Compare/slice";
import AnalyticBar from "./AnalyticBar";
import AnalyticsFilter from "./analyticsFilters/AnalyticsFilter";
import Loading from "../loading/Index";
import TotalCallVolumeTab from "./TotalCallVolumeTab";
import QuestionRateTab from "./QuestionRateTab";
import CallDurationTab from "./CallDurationTab";
import { toTimeFormat } from "./utils/date";
import TalkRatioTab from "./TalkRatioTab";
import TabInformation from "./TabInformation";
import styles from "./analytics.module.css";
import __ from "../../app/i18n";
import DownloadMonthlyReportButton from "./DownloadMonthlyReportButton";
import { AccessControl } from "../permissions/AccessControl";
import { PERMISSIONS } from "../../app/utils";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { selectIsCoachingEnabled, selectDashboards } from "../session/slice";
import MetabaseIframe from "./MetabaseIframe";
const { Content } = Layout;

const TopTabs = ({ isSecondary } = { isSecondary: false }) => {
  const { t } = useTranslation();
  const { selectAnalyticsState, selectIsComparing, selectIsFetching } =
    isSecondary ? secondarySlice : primarySlice;
  const isLoading = useSelector(selectIsFetching);
  const analyticsState = useSelector(selectAnalyticsState);
  const isComparing = useSelector(selectIsComparing);
  const isCoachingEnabled = useSelector(selectIsCoachingEnabled);
  const dashboards = useSelector(selectDashboards);
  const [rootTab, setRootTab] = useState("activity");

  const sections = useMemo(() => {
    const getSamuScoreProp = (prop) => {
      if (
        analyticsState &&
        analyticsState.samuScore &&
        Array.isArray(analyticsState.samuScore)
      ) {
        return (
          (analyticsState?.samuScore || []).find((a) => a.property === prop) ||
          {}
        );
      }

      return {};
    };

    const sections = [
      {
        key: "activity",
        name: t("analytics.dashboard.tabs.activity.label"),
        children: [
          {
            name: t(
              "analytics.dashboard.tabs.activity.children.callDuration.label"
            ),
            qty: toTimeFormat(analyticsState.callDuration.avg),
            unit: "min",
            component: () => (
              <CallDurationTab analyticsState={analyticsState} />
            ),
          },
          {
            name: t(
              "analytics.dashboard.tabs.activity.children.callVolume.label"
            ),
            qty: parseInt(analyticsState.totalCallVolume.avg),
            component: () => (
              <TotalCallVolumeTab analyticsState={analyticsState} />
            ),
          },
          {
            name: t(
              "analytics.dashboard.tabs.activity.children.talkRatio.label"
            ),
            qty: analyticsState.talkRatio.avg,
            unit: "%",
            component: () => <TalkRatioTab analyticsState={analyticsState} />,
          },
          {
            name: t(
              "analytics.dashboard.tabs.activity.children.questionRate.label"
            ),
            qty: parseInt(analyticsState.questionRate.avg),
            component: () => (
              <QuestionRateTab analyticsState={analyticsState} />
            ),
          },
        ],
      },
    ];
    if (!isCoachingEnabled) {
      sections.push({
        key: "samuScore",
        name: t("analytics.dashboard.tabs.samuScore.label"),
        children: [
          {
            name: t("analytics.dashboard.tabs.samuScore.children.totalScore"),
            qty: getSamuScoreProp("Summary").avg || 0,
            component: () => (
              <AnalyticBar
                avg={getSamuScoreProp("Summary").avg}
                userAnalytics={getSamuScoreProp("Summary").users}
              />
            ),
          },
          {
            name: t("analytics.dashboard.tabs.samuScore.children.preparation"),
            qty: getSamuScoreProp("Preparación del vendedor").avg,
            component: () => (
              <AnalyticBar
                avg={getSamuScoreProp("Preparación del vendedor").avg}
                userAnalytics={
                  getSamuScoreProp("Preparación del vendedor").users
                }
              />
            ),
          },
          {
            name: t("analytics.dashboard.tabs.samuScore.children.diagnosis"),
            qty: getSamuScoreProp("Diagnóstico hecho por el vendedor").avg,
            component: () => (
              <AnalyticBar
                avg={getSamuScoreProp("Diagnóstico hecho por el vendedor").avg}
                userAnalytics={
                  getSamuScoreProp("Diagnóstico hecho por el vendedor").users
                }
              />
            ),
          },
          {
            name: t("analytics.dashboard.tabs.samuScore.children.solution"),
            qty: getSamuScoreProp("Solución presentada").avg,
            component: () => (
              <AnalyticBar
                avg={getSamuScoreProp("Solución presentada").avg}
                userAnalytics={getSamuScoreProp("Solución presentada").users}
              />
            ),
          },
          {
            name: t(
              "analytics.dashboard.tabs.samuScore.children.dataCollectionFromPurchasingProcess"
            ),
            qty: getSamuScoreProp(
              "Obtención de datos del proceso de Compra del prospecto"
            ).avg,
            component: () => (
              <AnalyticBar
                avg={
                  getSamuScoreProp(
                    "Obtención de datos del proceso de Compra del prospecto"
                  ).avg
                }
                userAnalytics={
                  getSamuScoreProp(
                    "Obtención de datos del proceso de Compra del prospecto"
                  ).users
                }
              />
            ),
          },
          {
            name: t(
              "analytics.dashboard.tabs.samuScore.children.definitionOfNextSteps"
            ),
            qty: getSamuScoreProp("Definición de próximos pasos").avg,
            component: () => (
              <AnalyticBar
                avg={getSamuScoreProp("Definición de próximos pasos").avg}
                userAnalytics={
                  getSamuScoreProp("Definición de próximos pasos").users
                }
              />
            ),
          },
          {
            name: t(
              "analytics.dashboard.tabs.samuScore.children.connectionBetweenPeople"
            ),
            qty: getSamuScoreProp("Conexión entre las personas").avg,
            component: () => (
              <AnalyticBar
                avg={getSamuScoreProp("Conexión entre las personas").avg}
                userAnalytics={
                  getSamuScoreProp("Conexión entre las personas").users
                }
              />
            ),
          },
        ],
      });
    }

    if (dashboards && Array.isArray(dashboards) && dashboards.length) {
      for (const dashboardGroup of dashboards) {
        sections.push({
          key: dashboardGroup.name,
          name: dashboardGroup.name,
          children: dashboardGroup.dashboards.map((dash) => ({
            name: dash.name,
            component: () => <MetabaseIframe name={dash.name} url={dash.url} />,
          })),
        });
      }
    }

    return sections;
  }, [analyticsState, isCoachingEnabled, t, dashboards]);

  if (!analyticsState) {
    return <h2>Loading</h2>;
  }

  const renderTabBar = (props, DefaultTabBar) => <DefaultTabBar {...props} />;

  const items = (rootItem) =>
    rootItem.children.map((_, i) => {
      const id = String(i + 1);
      return {
        label: (
          <div>
            <div>
              <Typography.Text level={4}>{`${_.name}`}</Typography.Text>
            </div>
            {_.qty && (
              <div style={{ marginTop: "5px" }}>
                <Typography.Title level={3}>
                  {`${_.qty}`}
                  {_.unit ? (
                    <Typography.Text
                      level={6}
                      style={{ fontSize: "15px" }}
                    >{`${_.unit}`}</Typography.Text>
                  ) : (
                    ""
                  )}
                </Typography.Title>
              </div>
            )}
          </div>
        ),
        key: id,
        children: _.component ? _.component() : "",
      };
    });

  const analytiRootItems = (sections || []).map((_, i) => {
    const id = String(i + 1);

    return {
      label: _.name,
      key: _.key,
      children: isLoading ? (
        <Loading />
      ) : (
        <Tabs
          className="activity-tabs"
          defaultActiveKey="1"
          renderTabBar={renderTabBar}
          items={items(_)}
        />
      ),
    };
  });

  let containerClassName = "";
  if (isComparing) {
    containerClassName =
      containerClassName + styles.analyticsContainerComparing;
  }

  if (isSecondary) {
    containerClassName =
      containerClassName + " " + styles.analyticsContainerSecondary;
  }

  return (
    <>
      <Content className={containerClassName} style={{ padding: "0" }}>
        <Layout
          className={"site-layout-background main-layout-container"}
          style={{ padding: "24px 0" }}
        >
          <Content style={{ padding: "0 24px", minHeight: 280 }}>
            <AccessControl permission={PERMISSIONS.ANALYTICS.DOWNLOAD_REPORT}>
              <DownloadMonthlyReportButton />
            </AccessControl>

            <TabInformation />
            {isComparing && rootTab === "activity" ? (
              <AnalyticsFilter
                isComparing={isComparing}
                isSecondary={isSecondary}
              />
            ) : (
              ""
            )}
            <Tabs
              defaultActiveKey="1"
              renderTabBar={renderTabBar}
              items={analytiRootItems}
              className={"topTabsContainer"}
              type="card"
              onChange={(v) => setRootTab(v)}
              tabBarExtraContent={
                !isComparing && rootTab === "activity" ? (
                  <AnalyticsFilter isComparing={isComparing} />
                ) : (
                  ""
                )
              }
            />
          </Content>
        </Layout>
      </Content>
    </>
  );
};

export default TopTabs;
