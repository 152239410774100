import { Modal, Form, Input, Select, Typography } from "antd";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SAMU_INTERNAL_CALLTYPES } from "../team/interactionCallType/slice";
import { usePermissionStatus } from "../../hooks/permissions/usePermissionStatus";
import { PERMISSIONS } from "../../app/utils";
export default function MeetingDetailHeaderEdit({
  visible,
  onCancel,
  onOk,
  meeting,
  loading,
  users,
  stakeholders,
  callTypes,
}) {
  const { t } = useTranslation();
  const [editName, setEditName] = useState(meeting?.name);
  const [editCallType, setEditCallType] = useState(meeting?.callTypeId);
  const [editHost, setEditHost] = useState(meeting?.hostId);
  const [editStakeholders, setEditStakeholders] = useState();
  const [editUsers, setEditUsers] = useState();
  const permissionsStatus = usePermissionStatus([
    PERMISSIONS.MEETINGS.UPDATE,
    PERMISSIONS.MEETINGS.MODIFY_MEETING_NAME,
    PERMISSIONS.MEETINGS.MODIFY_HOST,
  ])

  const isUnqualified = useMemo(() => {
    return meeting.callType === SAMU_INTERNAL_CALLTYPES.UNQUALIFIED._id;
  }, [meeting]);

  useEffect(() => {
    if (visible) {
      setEditName(meeting?.name);
      setEditCallType(
        callTypes?.find((ct) => ct.name === meeting?.callType)?._id
      );
      setEditHost(meeting?.hostId);
      const validStakeholders = meeting?.stakeholders;
      const validUsers =
        users
          ?.filter((u) => meeting?.users?.some((id) => u.email === id.email))
          .map((u) => u.id) || [];
      setEditStakeholders(validStakeholders);
      setEditUsers(validUsers);
    }
  }, [visible, meeting, stakeholders, users, callTypes]);

  const callTypeOptions =
    callTypes?.map((ct) => ({
      label: ct.name,
      value: ct._id,
    })) || [];

  const userOptions =
    users?.map((user) => ({
      label: user.name,
      value: user.id,
    })) || [];

  const stakeholderOptions =
    meeting?.invitations
      ?.filter((invitation) => invitation.responseStatus === "accepted")
      ?.map((invitation) => ({
        label: invitation.email,
        value: invitation.email,
      })) || [];

  const handleSubmit = () => {
    const callTypeName = callTypes?.find((ct) => ct._id === editCallType)?.name;
    onOk({
      name: editName,
      callTypeId: callTypeName === meeting?.callType ? undefined : editCallType,
      hostId: editHost,
      stakeholderIds: editStakeholders,
      userIds: editUsers,
    });
  };

  const handleEditStakeholders = (value) => {
    setEditStakeholders(
      value.filter((stakeholder) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(stakeholder)
      )
    );
  };

  const callTypeChanged = meeting.status === "CALLTYPE_CHANGED";

  return (
    <Modal
      title={t("meetings.details.sections.edit.title")}
      open={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={loading}
      width={600}
    >
      <Form layout="vertical">
        <Form.Item
          label={t("meetings.details.sections.edit.name")}
          required
          rules={[
            { required: true, message: t("generic.validation.required") },
          ]}
        >
          <Input
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            placeholder={t("generic.placeholders.enterMeetingName")}
            disabled={!permissionsStatus[PERMISSIONS.MEETINGS.MODIFY_MEETING_NAME] && !permissionsStatus[PERMISSIONS.MEETINGS.UPDATE]}
          />
        </Form.Item>

        <Form.Item label={t("meetings.details.sections.edit.callType")}>
          <Select
            value={
              isUnqualified
                ? t("meetings.details.sections.edit.unqualified")
                : editCallType
            }
            onChange={setEditCallType}
            placeholder={t(
              "meetings.details.sections.edit.callTypePlaceholder"
            )}
            options={callTypeOptions}
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            disabled={meeting.status === "CALLTYPE_CHANGED" ? "disabled" : "" || !permissionsStatus[PERMISSIONS.MEETINGS.UPDATE]}
          />
          {callTypeChanged ? (
            <Typography.Text type="secondary">
              {t("meetings.details.sections.edit.callTypeChanged")}
            </Typography.Text>
          ) : (
            ""
          )}
        </Form.Item>

        <Form.Item label={t("meetings.details.sections.edit.host")}>
          <Select
            value={editHost}
            onChange={setEditHost}
            placeholder={t("meetings.details.sections.edit.hostPlaceholder")}
            options={userOptions}
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            disabled={!permissionsStatus[PERMISSIONS.MEETINGS.UPDATE] || !permissionsStatus[PERMISSIONS.MEETINGS.MODIFY_HOST]}
          />
        </Form.Item>

        <Form.Item label={t("meetings.details.sections.edit.stakeholders")}>
          <Select
            mode="tags"
            value={editStakeholders}
            onChange={handleEditStakeholders}
            placeholder={t(
              "meetings.details.sections.edit.stakeholdersPlaceholder"
            )}
            options={stakeholderOptions}
            disabled={!permissionsStatus[PERMISSIONS.MEETINGS.UPDATE]}
          />
        </Form.Item>

        <Form.Item label={t("meetings.details.sections.edit.users")}>
          <Select
            mode="multiple"
            value={editUsers}
            onChange={setEditUsers}
            placeholder={t("meetings.details.sections.edit.usersPlaceholder")}
            options={userOptions}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            disabled={!permissionsStatus[PERMISSIONS.MEETINGS.UPDATE]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
