import {
    Button,
    Card,
    Table,
    Modal,
    Dropdown,
    Menu,
    Tooltip,
    Typography,
    Input,
    Tag,
  } from "antd";
  import { useDispatch, useSelector } from "react-redux";
  import GoogleCalendarLogo from "../../team/general/profile/tabs/assets/googleCalendarLogo.png";
  import WhatsAppLogo from "../../team/general/profile/tabs/assets/whatsAppIcon.png";
  import { SearchOutlined } from "@ant-design/icons";
  import TeamsLogo from "../../team/general/profile/tabs/assets/teamsLogo.png";
  
  import { isFetching } from "../filters/slice";
  import { loadTeams, selectTeams } from "../teams/slice";
  import {
    DeleteOutlined,
    WarningOutlined,
    ReloadOutlined,
    DownOutlined,
    LoadingOutlined,
    EditOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
  } from "@ant-design/icons";
  import { useEffect, useState } from "react";
  import {
    deleteUser,
    loadUsers,
    resetPassword,
    selectUsers,
    toggleUserAccess,
  } from "../users/slice";
  import NewTeamUser from "../users/NewTeamUser";
  import EditTeamUser from "../users/EditTeamUser";
  import styles from "../team.module.css";
  import __ from "../../../app/i18n";
  import Api from "../../../app/api";
  import { Language, AlertTriangle } from "tabler-icons-react";
  import { PERMISSIONS, appMeetingLangs, removeAccents } from "../../../app/utils";
  import Status from "../users/Status";
  import { AccessControl } from "../../permissions/AccessControl";
import { useUserHasPermission } from "../../../hooks/permissions/useUserHasPermission";
import { loadRoles, selectRoles } from "../roles/slice";
import { usePermissionStatus } from "../../../hooks/permissions/usePermissionStatus";
import i18n from "../../../app/i18n/config";
import { loadPermissions } from "../permissions/slice";
  
  const SECTIONS = {
    NEW_USER: "newUser",
    EDIT_USER: "editUser",
  };
  
  const loadingCards = (
    <>
      <Card loading={true} style={{ marginBottom: "15px" }} />
      <Card loading={true} style={{ marginBottom: "15px" }} />
      <Card loading={true} style={{ marginBottom: "15px" }} />
      <Card loading={true} style={{ marginBottom: "15px" }} />
      <Card loading={true} style={{ marginBottom: "15px" }} />
      <Card loading={true} style={{ marginBottom: "15px" }} />
      <Card loading={true} style={{ marginBottom: "15px" }} />
    </>
  );
  
  export function UsersView() {
    const selectIsFetching = useSelector(isFetching);
    const roles = useSelector(selectRoles);
    const hasAnyUserPermission = useUserHasPermission([PERMISSIONS.USERS.DELETE, PERMISSIONS.USERS.RESET_PASSWORD, PERMISSIONS.USERS.DEACTIVATE, PERMISSIONS.USERS.UPDATE], false);
    const userPermissionsStatus = usePermissionStatus([
      PERMISSIONS.USERS.DELETE,
      PERMISSIONS.USERS.RESET_PASSWORD,
      PERMISSIONS.USERS.DEACTIVATE,
      PERMISSIONS.USERS.UPDATE
    ])
    const [searchText, setSearchText] = useState("");
    const users = useSelector(selectUsers);
    const [section, setSection] = useState(false);
    const [userForDelete, setUserForDelete] = useState(false);
    const [userForEdit, setUserForEdit] = useState(false);
    const [selectedUserTeams, setSelectedUserTeams] = useState(null);
    const [googleConnections, setGoogleConnections] = useState(null);
    const [whatsAppConnections, setWhatsAppConnections] = useState([]);
    const [microsoftConnections, setMicrosoftConnections] = useState([]);
    const [usersWithoutScope, setUsersWithoutScope] = useState(null);
    const [pageSize, setPageSize] = useState(25);
    const [seats, setSeats] = useState();
    const [connectionsFetched, setConnectionsFetched] = useState(false);
    const [expandedUsers, setExpandedUsers] = useState(new Set());
    const dispatch = useDispatch();
    document.title = `${__("appName")} - ${__("team.label")}`;
    const [filteredUsers, setFilteredUsers] = useState(users);
    const teams = useSelector(selectTeams);
  
    const connections = [
      ...(googleConnections || []), 
      ...(whatsAppConnections || []), 
      ...(microsoftConnections || [])
    ].filter((account, index, arr) => arr.indexOf(account) === index).length;
    
    useEffect(() => {
      dispatch(loadUsers());
      dispatch(loadRoles());
      dispatch(loadPermissions());
      dispatch(loadTeams());
  
      Api("googleConnections", {}).then((data) => {
        setGoogleConnections(data.usersWithGoogle);
        setWhatsAppConnections(data.usersWithWhatsApp);
        setMicrosoftConnections(data.usersWithMicrosoft);
        setUsersWithoutScope(data.usersWithGoogleWithoutScope);
        setSeats(data.seats);
        setConnectionsFetched(true);
      });
    }, [dispatch]);
  
    useEffect(() => setFilteredUsers(users), [users]);
  
    const handleSearch = (e) => {
      const value = removeAccents(e.target.value.toLowerCase().trim());
      setSearchText(value);
  
      const filteredData = users.filter(
        (user) =>
          removeAccents(user.name.toLowerCase()).includes(value) ||
          user.email.toLowerCase().includes(value)
      );
      setFilteredUsers(filteredData);
    };
  
    const handleNewUser = () => {
      setSection(SECTIONS.NEW_USER);
    };
  
    const handleEditUser = (user) => {
      setUserForEdit(user);
      setSection(SECTIONS.EDIT_USER);
    };
  
    const showUserDeleteModal = (id) => {
      setUserForDelete(id);
    };
  
    const handleToggleUserAccess = (userId) => {
      dispatch(toggleUserAccess({ id: userId }, { id: userId }));
    };
  
    const handleDeleteUser = () => {
      dispatch(deleteUser({ id: userForDelete }, {}));
      setUserForDelete(null);
    };
  
    const handleResetPassword = (userId) => {
      dispatch(resetPassword({ userId }));
    };
  
    const isGoogleConnected = (user) => {
      if (!googleConnections) {
        return <LoadingOutlined style={{ fontSize: 15 }} spin />;
      }
  
      if (
        Array.isArray(googleConnections) &&
        googleConnections.includes(user.id)
      ) {
        return (
          <img
            width="20px"
            src={GoogleCalendarLogo}
            title="Conectado a Google"
            alt="Conectado a Google"
          />
        );
      } else if (
        Array.isArray(usersWithoutScope) &&
        usersWithoutScope.includes(user.id)
      ) {
        return (
          <Tooltip title={__("generic.buttons.needPermission")}>
            <div style={{ paddingLeft: "16px" }}>
              <AlertTriangle color="#faad15" />
            </div>
          </Tooltip>
        );
      }
    };
    const isWhatsAppConnected = (user) => {
      if (!googleConnections) {
        return <LoadingOutlined style={{ fontSize: 15 }} spin />;
      }
  
      if (
        Array.isArray(whatsAppConnections) &&
        whatsAppConnections.includes(user.id)
      ) {
        return (
          <img width="20px" src={WhatsAppLogo} title="WhatsApp" alt="WhatsApp" />
        );
      }
    };
  
    const isTeamsConnected = (user) => {
      if (!googleConnections) {
        return <LoadingOutlined style={{ fontSize: 15 }} spin />;
      }
  
      if (
        Array.isArray(microsoftConnections) &&
        microsoftConnections.includes(user.id)
      ) {
        return (
          <img
            width="20px"
            src={TeamsLogo}
            title="Microsoft Teams"
            alt="Microsoft Teams"
          />
        );
      }
    };
  
    const actions = (user) => {
      const menu = (
        <Menu>
          {userPermissionsStatus[PERMISSIONS.USERS.UPDATE] && (
            <Menu.Item
              icon={<EditOutlined style={{ fontSize: "20px" }} />}
              onClick={() => handleEditUser(user)}
            >
              {__("team.edit")}
            </Menu.Item>
          )}
          {userPermissionsStatus[PERMISSIONS.USERS.DEACTIVATE] && (
            <Menu.Item
            hidden={!user.enabled}
            icon={
              <WarningOutlined style={{ fontSize: "20px", color: "#ffa800" }} />
            }
            onClick={() => handleToggleUserAccess(user.id)}
            >
              {__("team.deactivate")}
            </Menu.Item>
          )}
          {userPermissionsStatus[PERMISSIONS.USERS.DELETE] && (
            <Menu.Item
              icon={
              <DeleteOutlined
                style={{
                  fontSize: "20px",
                  color: "red",
                }}
              />
              }
              onClick={() => showUserDeleteModal(user.id)}
            >
              {__("generic.fields.delete")}
            </Menu.Item>
          )}
        {userPermissionsStatus[PERMISSIONS.USERS.RESET_PASSWORD] && (
          <Menu.Item
            icon={
              <ReloadOutlined
                style={{
                  fontSize: "20px",
                  color: "green",
                }}
              />
            }
            onClick={() => handleResetPassword(user.id)}
          >
            {__("generic.fields.resetPassword")}
          </Menu.Item>         
        )}
        </Menu>
      );
  
      return (
        <Dropdown overlay={menu}>
          <a>
            {__("team.actions")} <DownOutlined />
          </a>
        </Dropdown>
      );
    };
  
    const toggleTeamsExpand = (userId) => {
      setExpandedUsers(prev => {
        const newSet = new Set(prev);
        if (newSet.has(userId)) {
          newSet.delete(userId);
        } else {
          newSet.add(userId);
        }
        return newSet;
      });
    };
  
    const showAllTeams = (user) => {
      const userTeams = teams
        .filter((team) => team.users.includes(user.id))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
      setSelectedUserTeams({ user, teams: userTeams });
    };
  
    const columns = [
      {
        title: __("team.list.name"),
        dataIndex: "name",
        key: "name",
        render: (name, user) => renderName(user),
        sorter: (a, b) => (a.name < b.name ? -1 : 1),
      },
      {
        title: __("team.team"),
        dataIndex: "team",
        key: "team",
        width: 100,
        render: (_, user) => {
          const maxTeamsQty = 3;
          const userTeams = teams
            .filter((team) => team.users.includes(user.id))
            .sort((a, b) => (a.name < b.name ? -1 : 1));
  
          const teamsToShow = userTeams.slice(0, maxTeamsQty);
          const remainingCount = userTeams.length - maxTeamsQty;
  
          return (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "4px", alignItems: "center" }}>
              {teamsToShow.map((team) => (
                <Tag key={team.id} color={team.color}>
                  {team.name}
                </Tag>
              ))}
              {userTeams.length > maxTeamsQty && (
                <Button 
                  type="link" 
                  size="small" 
                  onClick={(e) => {
                    e.stopPropagation();
                    showAllTeams(user);
                  }}
                  style={{ padding: "0 4px", height: "auto" }}
                >
                  {`+${remainingCount}`}
                </Button>
              )}
            </div>
          );
        },
        filters: teams
          .map((team) => ({ text: team.name, value: team._id }))
          .sort((a, b) => (a.text < b.text ? -1 : 1)),
        onFilter: (teamId, user) => {
          const team = teams.find((t) => t._id === teamId);
          if (Array.isArray(team.users) && team.users.length === 0) {
            return true;
          }
  
          return team.users.includes(user.id);
        },
      },
      {
        title: __("team.list.email"),
        dataIndex: "email",
        key: "email",
        render: (name, user) => user.email,
        sorter: (a, b) => (a.email < b.email ? -1 : 1),
      },
      {
        title: __("team.list.role"),
        dataIndex: "role",
        key: "role",
        render: (name, user) => {
          const userRoles = roles
            .filter((role) => user.access.includes(role._id))
            .sort((a, b) => (a.name < b.name ? -1 : 1));

          return <div style={{ display: "flex", flexWrap: "wrap", gap: "4px", alignItems: "center" }}>
              {userRoles.map((role) => (
                <Tag key={role._id}>
                  {role.name}
                </Tag>
              ))}
              </div>
        },
        sorter: (a, b) => (a.name < b.name ? -1 : 1),
        filters: roles
        .map((role) => ({ text: role.name, value: role._id }))
        .sort((a, b) => (a.text < b.text ? -1 : 1)),
        onFilter: (roleId, user) => {
          if(!Array.isArray(user.access)) {
            return false;
          }
          
          return user.access.includes(roleId);
        },
      },
      {
        title: __("team.list.status"),
        dataIndex: "status",
        key: "status",
        filters: [
          { text: __("team.list.active"), value: true },
          { text: __("team.list.deactive"), value: false },
        ],
        onFilter: (value, record) => record.enabled === value,
        render: (name, user) =>
          user.enabled ? (
            <CheckCircleTwoTone
              style={{ fontSize: "15px" }}
              twoToneColor={"#52c419"}
            />
          ) : (
            <CloseCircleTwoTone
              style={{ fontSize: "15px" }}
              twoToneColor={"#ff4d4f"}
            />
          ),
        sorter: (a, b) => (a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1),
      },
      {
        title: (
          <>
            <img
              width="20px"
              src={GoogleCalendarLogo}
              title="Conectado a Google"
              alt="Conectado a Google"
            />
            <img
              width="20px"
              src={WhatsAppLogo}
              title="WhatsApp"
              alt="WhatsApp"
            />
            <img width="20px" src={TeamsLogo} title="Teams" alt="Teams" />
          </>
        ),
        dataIndex: "recording",
        key: "recording",
        filters: [
          {
            text: (
              <img
                width="20px"
                src={GoogleCalendarLogo}
                title="Conectado a Google"
                alt="Conectado a Google"
              />
            ),
            value: "google",
          },
          {
            text: (
              <img
                width="20px"
                src={WhatsAppLogo}
                title="WhatsApp"
                alt="WhatsApp"
              />
            ),
            value: "whatsApp",
          },
          {
            text: <img width="20px" src={TeamsLogo} title="Teams" alt="Teams" />,
            value: "teams",
          },
        ],
        onFilter: (value, user) => {
          return (
            (value === "google" && isGoogleConnected(user)) ||
            (value === "whatsApp" && isWhatsAppConnected(user)) ||
            (value === "teams" && isTeamsConnected(user))
          );
        },
        render: (_, user) => (
          <>
            {isGoogleConnected(user)} {isWhatsAppConnected(user)}{" "}
            {isTeamsConnected(user)}{" "}
          </>
        ),
      },
      {
        title: (
          <Language style={{ height: "20px", width: "20px", marginTop: "5px" }} />
        ),
        dataIndex: "lang",
        key: "lang",
        render: (_, user) => (
          <span
            className={`flag flag-country-${
              i18n.t(appMeetingLangs.find((l) => l.value === user.lang).flag)
            }`}
          ></span>
        ),
      },
      hasAnyUserPermission &&{
        dataIndex: "actions",
        key: "actions",
        render: (_, user) => actions(user),
      },
    ].filter(Boolean);
  
    const renderName = (user) => {
      return (
        <>
          {initials(user)}
          {user.name}
        </>
      );
    };
  
    const initials = (user) => {
      if (user.image) {
        return (
          <img
            className={styles.initialsBuble}
            src={user.image}
            alt={user.name}
          />
        );
      }
  
      const initials = user.name
        .split(" ")
        .map((w) => w.charAt(0))
        .join("");
  
      return <span className={styles.initialsBuble}>{initials}</span>;
    };
  
    return (
      <>
              <Modal
                title={__("generic.fields.needConfirmTitle")}
                open={!!userForDelete}
                onOk={() => handleDeleteUser()}
                onCancel={() => setUserForDelete(null)}
                okText={__("generic.fields.confirm")}
                cancelText={__("generic.fields.cancel")}
              >
                <p>{__("generic.fields.needConfirmContent")}</p>
              </Modal>
  
              <Modal
                title={selectedUserTeams?.user.name}
                open={!!selectedUserTeams}
                onCancel={() => setSelectedUserTeams(null)}
                footer={null}
              >
                <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                  {selectedUserTeams?.teams.map((team) => (
                    <Tag key={team.id} color={team.color}>
                      {team.name}
                    </Tag>
                  ))}
                </div>
              </Modal>
  
              <Typography.Title
                level={3}
                style={{ fontWeight: 400, marginTop: "2rem" }}
              >
                {__("team.users.label")}
              </Typography.Title>
              <Status
                {...{
                  users,
                  isWhatsAppConnected,
                  isGoogleConnected,
                  isTeamsConnected,
                  isFetching: !connectionsFetched,
                  seats,
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span className={styles.topRightSection}>
                  <Input
                    placeholder={__("team.list.search")}
                    value={searchText}
                    onChange={handleSearch}
                    style={{ width: 300, marginRight: "15px" }}
                    prefix={<SearchOutlined />}
                    allowClear
                  />
                  <AccessControl permission={PERMISSIONS.USERS.CREATE}>
                    <Button
                      onClick={handleNewUser}
                      type="primary"
                      className={styles.addTeamCircle}
                    >
                      +
                    </Button>
                  </AccessControl>
                </span>
              </div>
              {selectIsFetching ? (
                loadingCards
              ) : (
                <>
                  <Table
                    key={"team:table"}
                    columns={columns}
                    pagination={{ pageSize, onShowSizeChange: (_, s) => setPageSize(s) }}
                    pageSizeOptions={[10, 25, 50]}
                    dataSource={(filteredUsers || []).map((u) => ({
                      ...u,
                      key: u.id,
                    }))}
                    size="middle"
                  />
                </>
              )}
              <AccessControl permission={PERMISSIONS.USERS.CREATE}>
                <NewTeamUser
                  open={section === SECTIONS.NEW_USER}
                  onClose={() => setSection("")}
                  connections={connections ?? 0}
                  seats={seats ?? 0}
                />
              </AccessControl>
              <AccessControl permission={PERMISSIONS.USERS.UPDATE}>
                <EditTeamUser
                  open={section === SECTIONS.EDIT_USER && userForEdit}
                  onClose={() => {
                    setSection();
                    setUserForEdit();
                  }}
                  user={userForEdit}
                  connections={connections ?? 0}
                  seats={seats ?? 0}
                />
              </AccessControl>
            </>
    );
  }
  