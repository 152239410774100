import moment from "moment";
import __ from "./i18n";
import ptBR from "antd/es/locale/pt_BR";
import enUS from "antd/es/locale/en_US";
import esES from "antd/es/locale/es_ES";

export function getSecondsDiff(startDate, endDate) {
  let msInSecond = 1000;

  return Math.round(Math.abs(endDate - startDate) / msInSecond);
}

export function removeAccents(text) {
  const sustitutions = {
    àáâãäå: "a",
    ÀÁÂÃÄÅ: "A",
    èéêë: "e",
    ÈÉÊË: "E",
    ìíîï: "i",
    ÌÍÎÏ: "I",
    òóôõö: "o",
    ÒÓÔÕÖ: "O",
    ùúûü: "u",
    ÙÚÛÜ: "U",
    ýÿ: "y",
    ÝŸ: "Y",
    ß: "ss",
    ñ: "n",
    Ñ: "N",
  };
  // Devuelve un valor si 'letter' esta incluido en la clave
  function getLetterReplacement(letter, replacements) {
    const findKey = Object.keys(replacements).reduce(
      (origin, item, index) => (item.includes(letter) ? item : origin),
      false
    );
    return findKey !== false ? replacements[findKey] : letter;
  }
  // Recorre letra por letra en busca de una sustitución
  return text
    .split("")
    .map((letter) => getLetterReplacement(letter, sustitutions))
    .join("");
}

export const dateToVideoTime = (message, meeting) => {
  const timestamp =
    moment(message.startAt).diff(meeting.videoStartAt, "second") - 5;

  return timestamp;
};

export function convertTime(seconds) {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - hours * 3600) / 60);

  if (!!hours) {
    if (!!minutes) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else {
      return `${hours}h ${seconds}s`;
    }
  }
  if (!!minutes) {
    return `${minutes}m ${seconds}s`;
  }
  return `${seconds}s`;
}

export function getTimestampOfMessageToPlay(meeting, item) {
  if (["GOOGLE", "MICROSOFT"].includes(meeting.provider)) {
    const diff = moment(item.startAt).diff(meeting.videoStartAt, "second") - 1;

    return Math.abs(diff);
  }

  return item.startAt;
}

export const appName = "Samu.ai";

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

export function secondsToHHMMSS(totalSeconds) {
  var hours = Math.floor(totalSeconds / 3600);
  var minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  var seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);

  // Padding the values to ensure they are two digits
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds;
}

export function fixGPTEncoding(str) {
  var textarea = document.createElement("textarea");
  textarea.innerHTML = str;
  return textarea.value;
}

export const appMeetingLangsKeys = [
  "BRAZIL",
  "UK",
  "MEXICO",
  "HEBREW",
  "ITALIAN",
  "FRENCH",
];
export const appMeetingLangs = appMeetingLangsKeys.map((key) => ({
  value: key,
  label: __(`lang.${String(key).toLowerCase()}.label`),
  flag: __(`lang.${String(key).toLowerCase()}.flag`),
  small: __(`lang.${String(key).toLowerCase()}.small`),
}));

export const PERMISSIONS = Object.freeze({
  ACCOUNT: Object.freeze({
    UPDATE: "account.update",
    VIEW: "account.view",
  }),
  USERS: Object.freeze({
    CREATE: "users.create",
    DELETE: "users.delete",
    UPDATE: "users.update",
    LIST: "users.list",
    DEACTIVATE: "users.deactivate",
    RESET_PASSWORD: "users.reset_password",
  }),
  ROLES: Object.freeze({
    CREATE: "roles.create",
    UPDATE: "roles.update",
    DELETE: "roles.delete",
  }),
  APPS: Object.freeze({
    MANAGE: "apps.manage",
  }),
  ANALYTICS: Object.freeze({
    VIEW: "analytics.view",
    DOWNLOAD_REPORT: "analytics.download_report",
  }),
  CALENDAR: Object.freeze({
    CONNECT: "calendar.connect",
    MY_AGENDA: "calendar.my_agenda",
    TEAM_AGENDA: "calendar.team_agenda",
  }),
  MEETINGS: Object.freeze({
    UPDATE: "meetings.update",
    MODIFY_MEETING_NAME: "meetings.modify_meeting_name",
    CHANGE_PRIVACY: "meetings.change_privacy",
    DELETE_ALL: "meetings.delete_all",
    DELETE_TEAM_HOST: "meetings.delete_team_host",
    MODIFY_HOST: "meetings.modify_host",
    SCORE_DETAILS: "meetings.score_details",
    TEAM_VIEW: "meetings.team_view",
    ORG_VIEW: "meetings.organization_view",
    ADD_SAMU: "meetings.add_samu",
  }),
  COACHING: Object.freeze({
    VIEW: "coaching.view",
  }),
  TEAMS: Object.freeze({
    MANAGE: "teams.manage",
  }),
  CALL_TYPES: Object.freeze({
    CREATE: "call_types.create",
    DELETE: "call_types.delete",
    UPDATE: "call_types.update",
  }),
  LIBRARY: Object.freeze({
    CREATE: "library.create",
    UPDATE: "library.update",
    DELETE: "library.delete",
  }),
  INTEGRATIONS: Object.freeze({
    CONNECT_WHATSAPP: "integrations.connect_whatsapp",
  }),
});

export const getLanguagePrefix = (lang) => {
  switch (lang) {
    case "MEXICO":
      return "es";
    case "BRAZIL":
      return "pt";
    case "UK":
      return "en";
    default:
      return "en";
  }
};

export const getAntdLocaleByPrefix = (prefix) => {
  switch (prefix) {
    case "es":
      return esES;
    case "pt":
      return ptBR;
    case "en":
      return enUS;
    default:
      return enUS;
  }
};
