import { Tag, Popover, List, message } from "antd";
import React, { useState } from "react";
import styles from "../meetings/meetings.module.css";
import { Phone, Edit } from "tabler-icons-react";
import { useDispatch } from "react-redux";
import { AccessControl } from "../permissions/AccessControl";
import __ from "../../app/i18n";
import { loadMeetingAppTabs, updateCallType } from "../meeting/slice";
import { PERMISSIONS } from "../../app/utils";

const MeetingTags = ({ meeting, callTypes }) => {
  let { tags, callType: initialCallType } = meeting;
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const [currentCallType, setCurrentCallType] = useState(initialCallType);
  const [isUpdating, setIsUpdating] = useState(false);

  const sortedCallTypes = [...(callTypes || [])].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const handleCallTypeUpdate = async (newCallType) => {
    setVisible(false);
    setIsUpdating(true);
    const loadingMessage = message.loading({
      content: __("apps.generic.loading") || "Updating call type...",
      duration: 0,
    });

    try {
      await dispatch(
        updateCallType({
          meetingId: meeting?.id,
          callTypeId: newCallType._id,
          callTypeName: newCallType.name,
          interactionId: meeting?.interactionId,
        })
      ).unwrap();

      setCurrentCallType(newCallType.name);
      loadingMessage();
      message.success(
        __("apps.generic.alerts.updatedSucessFull") ||
          "Call type updated successfully"
      );
    } catch (error) {
      console.error("Error updating call type:", error);
      loadingMessage();
      message.error(
        __("apps.generic.alerts.error") || "Error updating call type"
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const content = (
    <List
      size="small"
      style={{
        maxHeight: "200px",
        overflow: "auto",
        minWidth: "150px",
        margin: 0,
        padding: 0,
      }}
      dataSource={sortedCallTypes}
      renderItem={(item) => (
        <List.Item
          onClick={() => !isUpdating && handleCallTypeUpdate(item)}
          style={{
            cursor: isUpdating ? "not-allowed" : "pointer",
            padding: "8px 12px",
            backgroundColor:
              currentCallType === item.name ? "#f0f0f0" : "transparent",
            transition: "background-color 0.3s",
            opacity: isUpdating ? 0.5 : 1,
          }}
          onMouseEnter={(e) => {
            if (!isUpdating) {
              e.currentTarget.style.backgroundColor = "#f5f5f5";
            }
          }}
          onMouseLeave={(e) => {
            if (!isUpdating) {
              e.currentTarget.style.backgroundColor =
                currentCallType === item.name ? "#f0f0f0" : "transparent";
            }
          }}
        >
          <Phone
            size={12}
            style={{
              marginRight: "8px",
              position: "relative",
              top: "1px",
            }}
          />
          {item.name}
        </List.Item>
      )}
    />
  );

  return (
    <>
      {tags ? (
        <span>
          {(tags || []).map((tag) => (
            <Tag key={tag.id} className={styles.meetingTag} onClose={(e) => {}}>
              {tag.name}
            </Tag>
          ))}
        </span>
      ) : (
        ""
      )}
      {currentCallType && currentCallType !== "undefined" ? (
        <AccessControl permission={PERMISSIONS.MEETINGS.UPDATE}>
          <span>
            <Tag className={styles.callTypeTag}>
              <Phone size={12} style={{ position: "relative", top: "1px" }} />{" "}
              {currentCallType}
              {callTypes?.length > 0 &&
                meeting.status !== "CALLTYPE_CHANGED" && (
                  <Popover
                    content={content}
                    trigger="click"
                    visible={visible}
                    onVisibleChange={setVisible}
                    placement="bottom"
                    overlayClassName={styles.noPaddingPopover}
                  >
                    <Edit
                      size={12}
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        position: "relative",
                        top: "1px",
                      }}
                    />
                  </Popover>
                )}
            </Tag>
          </span>
        </AccessControl>
      ) : (
        ""
      )}
    </>
  );
};

export default MeetingTags;
