import React, { useEffect, useState } from "react";
import { PermissionCategory } from "./PermissionCategory";
import { Collapse } from "antd";
import __ from "../../../app/i18n";

const { Panel } = Collapse;

export const PermissionCategoryList = ({ categories = {}, open = false, isPreview = false }) => {
  const [activeKey, setActiveKey] = useState([]);

  useEffect(() => {
    if (!open) {
      setActiveKey([]);
    }
  }, [open]);

  const handleCollapseChange = (keys) => {
    setActiveKey(keys);
  };

  return (
    <Collapse activeKey={activeKey} onChange={handleCollapseChange}>
      {Object.entries(categories).map(([categoryKey, categoryData]) => (
        <Panel header={__(categoryData.category.name)} key={categoryKey}>
          <PermissionCategory category={categoryData} isPreview={isPreview} />
        </Panel>
      ))}
    </Collapse>
  );
};