export const hasPermission = (user, permissions, requireAll = true) => {
  if (!user) return false;

  if (!Array.isArray(user.access)) {
    return false;
  }

  if (typeof permissions === "string") {
    return user.access.some(
      (access) =>
        Array.isArray(access.permissions) &&
        access.permissions.some((p) => p.key === permissions)
    );
  }

  if (!Array.isArray(permissions) || permissions.length === 0) {
    return false;
  }

  const hasPermissions = permissions.map((permission) =>
    user.access.some(
      (access) =>
        Array.isArray(access.permissions) &&
        access.permissions.some((p) => p.key === permission)
    )
  );

  return requireAll
    ? hasPermissions.every(Boolean)
    : hasPermissions.some(Boolean);
};
