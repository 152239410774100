import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectPermissions,
} from "../../features/team/permissions/slice";
import { PERMISSIONS } from "../../app/utils";
import {
  selectRoles,
} from "../../features/team/roles/slice";

export const useRecordingRoles = () => {
  const permissions = useSelector(selectPermissions);
  const roles = useSelector(selectRoles);

  const recordingRoles = useMemo(() => {
    const recordingPermissions = permissions.filter(
      (permission) => permission.key === PERMISSIONS.CALENDAR.CONNECT
    );

    const recordingRoles = roles.filter((role) =>
      recordingPermissions.every((permission) => {
        const permissionIds = role.permissions.map(
          (rolePermission) => rolePermission._id
        );
        return permissionIds.includes(permission._id);
      })
    );
    return recordingRoles;
  }, [permissions, roles]);

  const hasRecordingRole = useCallback((roleIds) => {
      return recordingRoles.some((role) => roleIds.includes(role._id));
    }, [recordingRoles]);


  return { recordingRoles, hasRecordingRole };
};
