const methods = {
    getRoles: {
      path: "/roles/list",
      method: "GET",
    },
    createRole: {
      path: "/roles/new",
      method: "POST",
    },
    updateRole: {
      path: ({id}) => `/roles/${id}`,
      method: "PUT",
    },
    deleteRole: {
      path: ({id}) => `/roles/${id}`,
      method: "DELETE"
    }
}
export default methods;