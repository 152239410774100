import { Typography, Tabs } from "antd";
import React, { useEffect } from "react";
import styles from "./organization.module.css";
import InformationTab from "./InformationTab";
import PlanTab from "./PlanTab";
import { useDispatch } from "react-redux";
import { getAccount } from "./slice";
import { useTranslation } from "react-i18next";
import ApiTab from "./ApiTab";

export default function OrganizationView() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getAccount());
  }, []);
  document.title = `${t("appName")} - ${t("team.menu.organization.label")}`;

  const initialTabs = [
    {
      label: t("team.organization.tab"),
      content: <InformationTab />,
    },
    {
      label: t("team.organization.plan.tab"),
      content: <PlanTab />,
    },
    {
      label: t("team.organization.api"),
      content: <ApiTab />,
    },
  ];

  return (
    <div>
      <Typography.Title
        level={3}
        style={{ fontWeight: 400, marginTop: "2rem" }}
      >
        {t("team.organization.title")}
      </Typography.Title>
      <div style={{ background: "#fff" }}>
        <Tabs
          tabBarStyle={{ background: "#f0f2f5" }}
          type="card"
          defaultActiveKey="information"
          tabPosition="top"
          items={initialTabs.map((tab, i) => ({
            label: tab.label,
            key: i,
            children: (
              <div className={styles.generalSectionsContainer}>
                {tab.content}
              </div>
            ),
          }))}
        />
      </div>
    </div>
  );
}
