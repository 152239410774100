
import { hasPermission } from "./utils";
import { PERMISSIONS } from "../../app/utils";
import { useMemo } from "react";

export const useUserIsManager = () => {
  return useMemo(() => {
    return (user) => hasPermission(user, PERMISSIONS.TEAMS.MANAGE);
  }, []);
};

export default useUserIsManager;