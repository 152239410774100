import { Form, Input, Button, Select, message, Tooltip, Modal } from "antd";
import styles from "./organization.module.css";
import { useTranslation } from "react-i18next";
import { CopyOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAccount, generateApiKey } from "./slice";

export default function ApiTab() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const account = useSelector(selectAccount);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      apiKey: account.apiKey,
    });
  }, [form, account]);

  const apiKey = Form.useWatch("apiKey", { form });

  function onCopyApiKey() {
    navigator.clipboard.writeText(apiKey).then(() => {
      message.success(t("team.organization.apiKey.copied"));
    });
  }

  function onGenerateApiKey() {
    setLoading(true);

    dispatch(generateApiKey())
      .then(({ payload }) => {
        message.success(t("team.organization.messages.updateSuccess"));
        form.setFieldValue("apiKey", payload.apiKey);
      })
      .catch(() => {
        message.error(t("team.organization.messages.updateError"));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className={styles.formContainer}>
      <Form form={form} layout="vertical">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Form.Item
            name="apiKey"
            label={t("team.organization.apiKey.title")}
            style={{ flexGrow: "100" }}
          >
            <Input disabled={true} />
          </Form.Item>
          <Tooltip title={t("team.organization.apiKey.copyApiKey")}>
            <Button
              type="primary"
              style={{ marginTop: "6px", marginLeft: "5px" }}
              onClick={onCopyApiKey}
            >
              <CopyOutlined />
            </Button>
          </Tooltip>
        </div>
        <Form.Item>
          <Button
            type="primary"
            onClick={() => {
              if (!apiKey) {
                onGenerateApiKey();
                return;
              }

              Modal.confirm({
                title: t("team.organization.apiKey.confirm.title"),
                content: t("team.organization.apiKey.confirm.body"),
                okText: t("confirmation.ok"),
                onOk: onGenerateApiKey,
                cancelText: t("confirmation.cancel"),
              });
            }}
            disabled={loading}
          >
            {t("team.organization.apiKey.regenerate")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
