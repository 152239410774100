import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../../../../../app/api";

// Thunks para manejar la API de Microsoft
export const fetchMicrosoftLoginData = createAsyncThunk(
  "microsoft/fetchLoginData",
  async () => {
    const response = await Api("getMicrosoftLoginData");
    return response;
  }
);

export const disconnectMicrosoft = createAsyncThunk(
  "microsoft/disconnect",
  async (_, { dispatch }) => {
    await Api("disconnectMicrosoft", {});
    dispatch(fetchMicrosoftLoginData()); // Recargar datos después de desconectar
  }
);

const initialState = {
  microsoftData: {},
  ready: false,
  loading: false,
  error: null,
};

const microsoftSlice = createSlice({
  name: "microsoft",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMicrosoftLoginData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMicrosoftLoginData.fulfilled, (state, action) => {
        state.microsoftData = action.payload;
        state.ready = true;
        state.loading = false;
      })
      .addCase(fetchMicrosoftLoginData.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const selectMicrosoftData = (state) => {
    return {
        microsoftData: state.microsoftCalendar.microsoftData,
        ready: state.microsoftCalendar.ready,
    }
};

export default microsoftSlice.reducer;
