import React, { useEffect } from "react";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import NeedLoginMessage from "./NeedLoginMessage";
import CanNotRecord from "./CanNotRecord";
import { useDispatch, useSelector } from "react-redux";
import { selectCanRecord } from "../../../../session/slice";
import __ from "../../../../../app/i18n";
import googleCalendarLogo from "./assets/googleCalendarLogo.png";
import TrialExpiredMessage from "./TrialExpiredMessage";
import {
  disconnectGoogle,
  fetchGoogleLoginData,
  selectGoogleData,
} from "./slices/googleSlice";
import { useTranslation } from "react-i18next";

const GoogleTab = ({ canConnectCalendar, isLoadingLicensesData }) => {
  const dispatch = useDispatch();
  const canRecord = useSelector(selectCanRecord);
  const { googleData, ready } = useSelector(selectGoogleData);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchGoogleLoginData());
  }, [canRecord, dispatch]);

  const handleLogout = () => {
    dispatch(disconnectGoogle());
  };

  if (!canRecord) {
    return <CanNotRecord />;
  }

  if (!ready || isLoadingLicensesData) {
    return <LoadingOutlined spin />;
  }

  if (ready && googleData.isLoggedIn) {
    return (
      <div>
        <img
          src={googleCalendarLogo}
          alt="Google Calendar"
          style={{ marginRight: "5px" }}
          width={25}
        />
        <CheckCircleOutlined
          style={{
            fontSize: "25px",
            color: "green",
            verticalAlign: "middle",
            display: "inline-block",
          }}
        />
        <Button style={{ marginLeft: "15px" }} onClick={() => handleLogout()}>
          {__("generic.buttons.disconnectGoogle")}
        </Button>
      </div>
    );
  } else if (!googleData.canDoLogin) {
    return (
      <>
        <TrialExpiredMessage />
      </>
    );
  } else {
    return (
      <>
        <NeedLoginMessage />
        <Tooltip title={canConnectCalendar ? "" : t("generic.errors.no_more_licenses")}>
          <span>
            <Button
              style={{ padding: "5px 10px", height: "auto" }}
              icon={
                <img
                  src={googleCalendarLogo}
                  alt="Google Calendar"
                  style={{ marginRight: "5px" }}
                  width={25}
                />
              }
              onClick={() => (document.location.href = googleData.url)}
              disabled={!canConnectCalendar}
            >
              <span>
                {t("menu.profile.tabs.integrations.syncButtons.with")}{" "}
                <b> Google Calendar</b>
              </span>
            </Button>
          </span>
        </Tooltip>
      </>
    );
  }
};

export default GoogleTab;
