import React, { useEffect, useMemo, useState } from 'react';
import { Layout, Typography, Table, Button, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import __ from '../../../app/i18n';
import styles from "../team.module.css";
import NewTeam from "./newTeam";
import EditTeam from './editTeam';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { loadUsersWithPermissions, selectIsFetching, selectUsersWithPermissions } from "../users/slice";
import { selectTeams, selectIsFetchingTeams, deleteTeam, loadTeams} from './slice';
import { PERMISSIONS, removeAccents } from '../../../app/utils';
import { AccessControl } from '../../permissions/AccessControl';
import { useUserHasPermission } from '../../../hooks/permissions/useUserHasPermission';
import i18n from '../../../app/i18n/config';

const { Content } = Layout;

const SECTIONS = {
  NEW_TEAM: "newTeam",
  EDIT_TEAM: "editTeam",
};

const formatUsersList = (userIds, allUsers, unknownLabel = 'Usuario desconocido') => {
  if (userIds.length === 0 || userIds.length === allUsers.length) {
    return i18n.t('generic.labels.all');
  }

  const names = userIds.map(userId => {
    const user = allUsers.find(user => user.id === userId);
    return user ? user.name : unknownLabel;
  });
  const joinedNames = names.join(', ');

  return joinedNames.length > 32 ? `${joinedNames.substring(0, 32)}...` : joinedNames;
};

export default function ViewTeams() {
  const dispatch = useDispatch();
  const canManageTeams = useUserHasPermission(PERMISSIONS.TEAMS.MANAGE);
  const users = useSelector(selectUsersWithPermissions);
  const isFetchingUsers = useSelector(selectIsFetching)
  const teams = useSelector(selectTeams);
  const isFetchingTeams = useSelector(selectIsFetchingTeams);
  const [filteredTeams, setFilteredTeams] = useState();
  const [searchText, setSearchText] = useState("");
  
  const [section, setSection] = useState("");
  const [teamForEdit, setTeamForEdit] = useState();

  const handleDeleteTeam = (id) => {
    dispatch(deleteTeam({ id }));
  };

  const handleEditTeam = (team) => {
    setTeamForEdit(team);
    setSection(SECTIONS.EDIT_TEAM);
  };

  useEffect(() => {
    dispatch(loadTeams());
    dispatch(loadUsersWithPermissions());
  }, [dispatch]);
  

  useEffect(() => {
    setFilteredTeams(teams);
  }, [teams]);

  const handleSearch = (e) => {
    const value = removeAccents(e.target.value.toLowerCase().trim());
    setSearchText(value);
    const filteredData = teams.filter(
      (team) => removeAccents(team.name.toLowerCase()).includes(value)
    );
    setFilteredTeams(filteredData);
  };

  const columns = useMemo(() => [
    {
      title: __("team.newTeam.name"),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: __("team.newTeam.users"),
      key: 'users',
      render: (_, team) => formatUsersList(team.users, users)
    },
    {
      title: __("team.newTeam.managers"),
      key: 'managers',
      render: (_, team) => formatUsersList(team.managers, users, 'Manager desconocido')
    },
    canManageTeams && {
      title: '',
      key: 'actions',
      render: (_, team) => (
        <div style={{ display: 'flex'}}>
          <AccessControl permission={PERMISSIONS.TEAMS.MANAGE}>
            <EditOutlined style={{ fontSize: "20px" }} onClick={() => handleEditTeam(team)} />
          </AccessControl>
          <AccessControl permission={PERMISSIONS.TEAMS.MANAGE}>
            <DeleteOutlined style={{ marginLeft: '.5rem', fontSize: "20px", color: "red" }} onClick={() => handleDeleteTeam(team._id)} />
          </AccessControl>
        </div>
      ),
    },
  ].filter(Boolean), [canManageTeams, handleDeleteTeam, users]);

  return (
    <Content>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem' }}>
        <Typography.Title level={3} style={{ fontWeight: 400 }}>{__("team.teams")}</Typography.Title>
        <span className={styles.topRightSection}>
          <Input
            placeholder={__("team.list.searchByName")}
            value={searchText}
            onChange={handleSearch}
            style={{ width: 300, marginRight: "15px" }}
            prefix={<SearchOutlined />}
            allowClear
          />
          <AccessControl permission={PERMISSIONS.TEAMS.MANAGE}>
            <Button
              onClick={() => setSection(SECTIONS.NEW_TEAM)}
              type="primary"
              className={styles.addTeamCircle}
            >
              +
            </Button>
          </AccessControl>
        </span>
      </div>
      {(!isFetchingTeams && !isFetchingUsers) && <Table columns={columns} dataSource={filteredTeams} size='middle'/>}
      <AccessControl permission={PERMISSIONS.TEAMS.MANAGE}>
        <NewTeam
          open={section === SECTIONS.NEW_TEAM}
          onClose={() => setSection("")}
        />
      </AccessControl>
      <AccessControl permission={PERMISSIONS.TEAMS.MANAGE}>
        <EditTeam 
          open={section === SECTIONS.EDIT_TEAM}
          team={teamForEdit}  
          onClose={() => { setSection(""); setTeamForEdit(null); }}
        />
      </AccessControl>
    </Content>
  );
}
