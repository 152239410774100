export const groupPermissionsByCategory = (allPermissions, rolePermissions = []) => {
    const categories = {};
  
    allPermissions.forEach((permission) => {
      if (!categories[permission.category.key]) {
        categories[permission.category.key] = {
          category: {
            key: permission.category.key,
            name: permission.category.name,
          },
          permissions: [],
        };
      }
    });
  
    rolePermissions.forEach((permission) => {
      categories[permission.category.key].permissions.push(permission);
    });
    
    return categories;
  };