import { useDispatch, useSelector } from "react-redux";
import { Badge, Drawer, Typography, Select, message } from "antd";
import { closeSection, selectMeeting, selectOpenSection } from "../../slice";
import { selectClients, loadClients } from "../../../team/users/slice";
import __ from "../../../../app/i18n";
import { useEffect, useState } from "react";
import { stringToColor } from "../../../../app/color";
import Section from "../Section";
import { EditOutlined } from "@ant-design/icons";
import API from "../../../../app/api";
import { removeAccents } from "../../../../app/utils";

const Deal = () => {
  const meeting = useSelector(selectMeeting);
  const clients = useSelector(selectClients);
  const [client, setClient] = useState();
  const [isEditingClient, setIsEditingClient] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setClient(clients.find(c => c.name === meeting.client)?._id);
    dispatch(loadClients());
  }, [meeting.stakeholders, meeting.users, meeting.client]);

 
  const handleUpdateClient = (value) => {
    setIsEditingClient(false);
    message.success(__('apps.generic.alerts.updatedSucessFull'));
    API('updateMeetingClient', { 
      meetingId: meeting.id, 
      clientId: value 
    });
    setClient(value);
  };
  return (
    <Section sectionKey="deal" title={__("meetings.details.sections.deal")}>
      {client ? (
        <>
          <Typography.Title level={4}>
            {__("meetings.filters.client")}
            <EditOutlined 
              style={{ marginLeft: 8, fontSize: 16, cursor: 'pointer' }} 
              onClick={() => setIsEditingClient(true)}
            />
          </Typography.Title>
          {isEditingClient ? (
            <Select
              style={{ width: '100%' }}
              value={client}
              onChange={handleUpdateClient}
              onBlur={() => setIsEditingClient(false)}
              autoFocus
              showArrow
              showSearch
              filterOption={(typed, item) => {
                const contains = String(item.label).toUpperCase().startsWith(String(removeAccents(typed)).toUpperCase())
                return contains;
              }}
              options={(clients || []).map((item, index) => ({
                key: `client#${item._id}`,
                label: item.name,
                value: item._id,
              }))}
            />
          ) : (
            <Typography.Text type="secondary">{clients.find(c => c._id === client)?.name}</Typography.Text>
          )}
        </>
      ) : (
        ""
      )}

      {meeting.providerLink ? (
        <>
          <Typography.Title level={4}>{__("deal.link")}</Typography.Title>
          <Typography.Text level={4}>
            <a href={meeting.providerLink}>{meeting.providerLink}</a>
          </Typography.Text>
        </>
      ) : (
        ""
      )}
      <Typography.Title level={4}>{__("deal.stakeholders")}</Typography.Title>
      <Typography.Text level={4}>
        {(meeting.stakeholders || []).map((stakeholderEmail, index) => (
          <>
            <Badge
              key={`bagde_${index}_index_guests`}
              color={stringToColor(stakeholderEmail)}
              text={
                <Typography.Text type="secondary">
                  {stakeholderEmail}
                </Typography.Text>
              }
            />
            <br />
          </>
        ))}

        {(meeting.stakeholders || []).length === 0 ? __("deal.empty") : ""}
      </Typography.Text>

      <Typography.Title level={4}>{__("deal.users")}</Typography.Title>
      <Typography.Text level={4}>
        {(meeting.users || []).map((u, index) => (
          <>
            <Badge
              key={`bagde_${index}_index_guests`}
              color={stringToColor(u.email)}
              text={
                <Typography.Text type="secondary">
                  {u.name} {u.lastName}
                </Typography.Text>
              }
            />
            <br />
          </>
        ))}

        {(meeting.users || []).length === 0 ? __("deal.empty") : ""}
      </Typography.Text>
    </Section>
  );
};

export default Deal;
