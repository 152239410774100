import {
  Col,
  Layout,
  Row,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { selectIsFetching, getTeamMeetings, selectMeetings, getMyMeetings } from "../slice";
import { selectUser } from "../../session/slice";
import MyNextMeetingsList from "./List";
import { Content } from "antd/lib/layout/layout";
import Filters from "../Filters";
import { loadUsers, selectUsers } from "../../team/users/slice";
import { useUserHasPermission } from "../../../hooks/permissions/useUserHasPermission";
import { PERMISSIONS } from "../../../app/utils";

const today = new Date();

const MyNextMeetings = () => {
  const dispatch = useDispatch();
  const canSeeTeamMeetings = useUserHasPermission(PERMISSIONS.CALENDAR.TEAM_AGENDA);
  const canSeeMyMeetings = useUserHasPermission(PERMISSIONS.CALENDAR.MY_AGENDA);
  const user = useSelector(selectUser);
  const isMeetingsFetching = useSelector(selectIsFetching);
  const meetings = useSelector(selectMeetings);
  const [host, setHost] = useState({ id: user._id, label: [user.name, user.lastName].join(' ') });
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(today.getMonth() + 1);
  const [rawDate, setRawDate] = useState(moment().format('YYYY-MM-DD'));
  const sellers = useSelector(selectUsers);
  const [loadedSellers, setLoadedSellers] = useState(false);

  useEffect(() => {
    loadMeetings();
  }, []);

  useEffect(() => {
    if (
      !sellers ||
      (Array.isArray(sellers) && !sellers.length && !loadedSellers)
    ) {
      dispatch(loadUsers());
      setLoadedSellers(true);
    }
  }, [dispatch, loadedSellers, sellers]);

  function loadMeetings () {
    const payload = {
      month: currentMonth,
      year: currentYear,
    };

    if (canSeeTeamMeetings) {
      dispatch(getTeamMeetings(payload));
    } else if(canSeeMyMeetings) {
          dispatch(getMyMeetings(payload));
    }
  }

  const onFilter = (params) => {
    if (params.date) {
      setCurrentYear(params.date.year);
      setCurrentMonth(params.date.month);
      setRawDate(params.date.raw.format('YYYY-MM-DD'));
    }

    setHost(params.host)
  };

  useEffect(() => {
    loadMeetings();
  }, [currentMonth, currentYear]);

  return (
    <Content style={{ padding: "0 25px" }}>
      <Layout
        className={
          "site-layout-background main-layout-container agenda-team-layout"
        }
        style={{ padding: "24px 0" }}
      >
        <Row style={{ minHeight: 280 }}>
          <Col md={5}>
            <Filters defaultHost={host} sellers={sellers} onFilter={(filters) => onFilter(filters)} />
          </Col>

          <Col md={19} className="next-meetings-tabs-container">
            <MyNextMeetingsList
              sellers={sellers}
              meetings={meetings}
              isMeetingsFetching={isMeetingsFetching}
              user={user}
              host={host}
              canEdit={true}
              date={rawDate}
              title={
                host && host.label
                  ? `Proximas reuniones de ${host.label}`
                  : "Proximas reuniones del equipo"
              }
            />
          </Col>
        </Row>
      </Layout>
    </Content>
  );
};

export default MyNextMeetings;
