import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Layout, Typography, Table, Button, Input, Tooltip, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import __ from "../../../app/i18n";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { PERMISSIONS, removeAccents } from "../../../app/utils";
import { loadRoles, selectIsFetchingRoles } from "./slice";
import { selectRoles } from "./slice";
import { deleteRole } from "./slice";
import { loadPermissions } from "../permissions/slice";
import EditRole from "./EditRole";
import CreateRole from "./NewRole";
import styles from "./role.module.css";
import teamStyles from "../team.module.css";
import { AccessControl } from "../../permissions/AccessControl";
import { usePermissionStatus } from "../../../hooks/permissions/usePermissionStatus";
import { useTranslation } from "react-i18next";
import { RolePreview } from "./RolePreview";

const { Content } = Layout;

const SECTIONS = {
  NEW_ROLE: "newRole",
  EDIT_ROLE: "editRole",
  ROLE_PREVIEW: "rolePreview",
};

export function RolesView() {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const permissionsStatus = usePermissionStatus([
    PERMISSIONS.ROLES.UPDATE,
    PERMISSIONS.ROLES.DELETE,
  ]);
  const { t } = useTranslation();
  const roles = useSelector(selectRoles);
  const rolesIsLoading = useSelector(selectIsFetchingRoles);
  const [section, setSection] = useState("");
  const [currentRole, setCurrentRole] = useState();
  const [searchText, setSearchText] = useState("");
  const [filteredRoles, setFilteredRoles] = useState(roles);
  const [roleToDelete, setRoleToDelete] = useState();

  const getTooltipMessage = useCallback(
    (role, permissionKey) => {
      if (!role.removableAndEditable)
        return __("generic.errors.role_not_delete_or_update");
      if (!permissionsStatus[permissionKey])
        return __("generic.errors.not_authorized");
      return "";
    },
    [permissionsStatus]
  );

  const isDisabled = useCallback(
    (role, permissionKey) => {
      return !role.removableAndEditable || !permissionsStatus[permissionKey];
    },
    [permissionsStatus]
  );

  const handleDeleteRole = useCallback(() => {
    dispatch(deleteRole({ id: roleToDelete._id }));
    setModalOpen(false);
  }, [dispatch, roleToDelete]);

  const getIconColor = useCallback(
    (role, permissionKey) => {
      return role.removableAndEditable && permissionsStatus[permissionKey]
        ? "red"
        : "inherit";
    },
    [permissionsStatus]
  );

  const handleEditRole = (role) => {
    setCurrentRole(role);
    setSection(SECTIONS.EDIT_ROLE);
  };

  const handleSearch = (e) => {
    const value = removeAccents(e.target.value.toLowerCase().trim());
    setSearchText(value);

    const filteredData = roles.filter((role) =>
      removeAccents(role.name.toLowerCase()).includes(value)
    );
    setFilteredRoles(filteredData);
  };

  useEffect(() => {
    dispatch(loadRoles());
    dispatch(loadPermissions());
  }, [dispatch]);

  useEffect(() => {
    setFilteredRoles(roles);
  }, [dispatch, roles]);

  const columns = useMemo(
    () =>
      [
        {
          title: __("generic.fields.name"),
          dataIndex: "name",
          key: "name",
        },
        {
          title: "",
          key: "actions",
          render: (_, role) => {
            return (
              <div className={styles.actionsContainer}>
                <Tooltip
                  title={getTooltipMessage(role, PERMISSIONS.ROLES.UPDATE)}
                >
                  <Button
                    type="text"
                    icon={
                      <EditOutlined
                        className={styles.actionButton}
                        onClick={() => handleEditRole(role)}
                      />
                    }
                    disabled={isDisabled(role, PERMISSIONS.ROLES.UPDATE)}
                  />
                </Tooltip>
                <Tooltip
                  title={getTooltipMessage(role, PERMISSIONS.ROLES.DELETE)}
                >
                  <Button
                    type="text"
                    icon={
                      <DeleteOutlined
                        className={styles.actionButton}
                        style={{
                          color: getIconColor(role, PERMISSIONS.ROLES.DELETE),
                        }}
                        onClick={() => {
                          setRoleToDelete(role);
                          setModalOpen(true);
                        }}
                      />
                    }
                    disabled={isDisabled(role, PERMISSIONS.ROLES.DELETE)}
                  />
                </Tooltip>
                <Button
                  type="text"
                  icon={
                    <EyeOutlined
                      className={styles.actionButton}
                      onClick={() => {
                        setCurrentRole(role);
                        setSection(SECTIONS.ROLE_PREVIEW);
                      }}
                    />
                  }
                />
              </div>
            );
          },
        },
      ].filter(Boolean),
    [getIconColor, getTooltipMessage, isDisabled]
  );

  return (
    <Content>
      <div className={styles.rolePageContainer}>
        <Typography.Title level={3} style={{ fontWeight: 400 }}>
          {__("team.roles")}
        </Typography.Title>
        <span className={teamStyles.topRightSection}>
          <Input
            placeholder={__("team.list.searchByName")}
            value={searchText}
            onChange={handleSearch}
            className={styles.searchInput}
            prefix={<SearchOutlined />}
            allowClear
          />
          <AccessControl permission={PERMISSIONS.ROLES.CREATE}>
            <Button
              onClick={() => setSection(SECTIONS.NEW_ROLE)}
              type="primary"
              className={teamStyles.addTeamCircle}
            >
              +
            </Button>
          </AccessControl>
        </span>
      </div>
      {rolesIsLoading && (
        <Table columns={columns} dataSource={filteredRoles} size="middle" />
      )}
      <AccessControl permission={PERMISSIONS.ROLES.UPDATE}>
        <EditRole
          open={section === SECTIONS.EDIT_ROLE}
          role={currentRole}
          onClose={() => {
            setSection("");
            setCurrentRole(null);
          }}
        />
      </AccessControl>
      <AccessControl permission={PERMISSIONS.ROLES.CREATE}>
        <CreateRole
          open={section === SECTIONS.NEW_ROLE}
          onClose={() => setSection("")}
        />
      </AccessControl>
      <Modal
        title={t("generic.fields.needConfirmTitle")}
        open={modalOpen}
        onOk={() => handleDeleteRole()}
        onCancel={() => setModalOpen(false)}
        okText={t("generic.fields.confirm")}
        cancelText={t("generic.fields.cancel")}
      >
        <p>{t("generic.fields.needConfirmContent")}</p>
      </Modal>
      <RolePreview open={section === SECTIONS.ROLE_PREVIEW} onClose={() => setSection("")} role={currentRole} />
    </Content>
  );
}
