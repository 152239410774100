import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../../app/api";
import { showGlobalErrors } from "../../loading/slice";

export const SAMU_INTERNAL_CALLTYPES = {
  UNQUALIFIED: {
    _id: '679b8293a6dfad853f41a986',
    name: 'UNQUALIFIED',
  }
}

const initialState = {
  status: "loading",
  interactionCallTypes: [],
  fetched: false,
  isNewModalOpen: false,
};

// Nueva función para cargar interactionCallTypes
export const loadInteractionCallTypes = createAsyncThunk(
  "interactionCallType/loadInteractionCallTypes",
  async (payload, { dispatch }) => {
    const response = await Api("getIcallTypes");
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

// Nueva función para crear interactionCallType
export const createInteractionCallType = createAsyncThunk(
  "interactionCallType/create",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await Api("postIcallTypes", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());
    dispatch(loadInteractionCallTypes());


    return response;
  }
);

// Nueva función para actualizar interactionCallType
export const updateInteractionCallType = createAsyncThunk(
  "interactionCallType/update",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await Api("putIcallTypes", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());
    dispatch(loadInteractionCallTypes());


    return response;
  }
);

export const deleteInteractionCallType = createAsyncThunk(
  "interactionCallType/delete",
  async (payload, { dispatch }) => {
    dispatch(startLoading());
    const response = await Api('deleteICallTypes', payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());
    dispatch(loadInteractionCallTypes());

    return response;
  }
);

export const interactionCallTypeSlice = createSlice({
  name: "interactionCallType",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = "loading";
    },
    stopLoading: (state) => {
      state.status = "iddle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createInteractionCallType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createInteractionCallType.fulfilled, (state, action) => {
        state.status = 'idle';
        state.interactionCallTypes = [...state.interactionCallTypes, action.payload];
      })

      .addCase(loadInteractionCallTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadInteractionCallTypes.fulfilled, (state, action) => {
        state.status = 'idle';
        state.interactionCallTypes = action.payload;
        state.fetched = true;
      })

      .addCase(deleteInteractionCallType.fulfilled, (state, action) => {
        state.status = 'idle';
        state.interactionCallTypes = state.interactionCallTypes.filter(type => type.id !== action.payload.id);
      })
  },
});

export const { clearFetched, startLoading, stopLoading, openNewModal, closeNewModal } = interactionCallTypeSlice.actions;

export const selectInteractionCallTypes = (state) => state.interactionCallType.interactionCallTypes;
export const selectIsFetchingInteraction = (state) => state.interactionCallType.status === 'idle';
export const selectInteractionCallTypesFetched = (state) => state.fetched;

export default interactionCallTypeSlice.reducer;
