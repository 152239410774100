import {
  Drawer,
  Button,
  Form,
  Input,
  Select,
  Checkbox,
  Tag,
} from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "./slice";
import { appMeetingLangs, PERMISSIONS, removeAccents } from "../../../app/utils";
import { selectTeams } from "../teams/slice";
import { selectRoles } from "../roles/slice";
import { useTranslation } from "react-i18next";
import { updateSessionUser } from "../../session/slice";
import { selectUser } from "../../session/slice";
import __ from "../../../app/i18n";
import { useUserHasPermission } from '../../../hooks/permissions/useUserHasPermission';

export default function EditTeamUser({ open, onClose, user, seats }) {
  const dispatch = useDispatch();
  const hasEditRoles = useUserHasPermission(PERMISSIONS.ROLES.UPDATE)
  const teams = useSelector(selectTeams);
  const roles = useSelector(selectRoles);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const ownuser = useSelector(selectUser);
  const [selectAllTeams, setSelectAllTeams] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    if (user && teams) {
      const userTeams = teams
        .filter((team) => team.users.includes(user.id || user._id))
        .map((team) => team._id);

      setSelectedTeams(userTeams);
      setSelectAllTeams(userTeams.length === teams.length);
      setSelectedRoles(user.access);

      form.setFieldsValue({
        name: user.uName,
        lastName: user.uLastName,
        email: user.email,
        access: user.access,
        role: String(user.role),
        lang: String(user.lang),
        teams: userTeams,
      });
    }
  }, [user, teams, form]);

  if (!open || !user) return null;

  const handleRolesChange = (value) => {
    setSelectedRoles(value);
  };

  const handleSelectAllTeamsChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAllTeams(isChecked);
    const newTeams = isChecked ? teams.map((team) => team._id) : [];
    setSelectedTeams(newTeams);
    form.setFieldsValue({ teams: newTeams });
  };

  const handleTeamsChange = (value) => {
    setSelectedTeams(value);
    setSelectAllTeams(value.length === teams.length);
  };

  const handleNewUser = (values) => {
    const isOwnUser = ownuser?.email === values?.email;

    dispatch(
      updateUser({
        ...values,
        userId: user.id || user._id,
        teams: selectAllTeams ? teams.map((team) => team._id) : selectedTeams,
        access: selectedRoles,
      })
    );

    if (isOwnUser) {
      dispatch(
        updateSessionUser({
          name: values.name,
          lastName: values.lastName,
          role: values.role,
        })
      );
    }

    onClose();
  };

  const langOptions = appMeetingLangs.map((lang) => (
    <Select.Option key={lang.value}>{t(lang.label)}</Select.Option>
  ));

  return (
    <Drawer
      title={t("team.list.editUser")}
      placement={"right"}
      width={500}
      onClose={() => onClose()}
      open={open}
      getContainer={true}
    >
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={handleNewUser}
        autoComplete="off"
        initialValues={{
          name: user.uName,
          lastName: user.uLastName,
          email: user.email,
          access: selectedRoles,
          lang: String(user.lang),
          teams: selectedTeams,
        }}
      >
        <Form.Item
          name="name"
          label={t("generic.fields.name")}
          rules={[
            {
              required: true,
              message: t("generic.required.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={t("generic.fields.lastName")}
          rules={[
            {
              required: true,
              message: t("generic.required.lastName"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: t("generic.required.email"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="access"
          label={__("team.roles")}
          rules={[
            {
              required: true,
              message: __("generic.required.userRole"),
            },
          ]}
        >
          <Select
            mode="multiple"
            onChange={handleRolesChange}
            style={{ width: "100%" }}
            optionLabelProp="label"
            filterOption={(typed, item) => {
              const contains = String(item.label)
                .toUpperCase()
                .startsWith(String(removeAccents(typed)).toUpperCase());
              return contains;
            }}
            disabled={!hasEditRoles}
          >
            {roles.map((role) => (
              <Select.Option key={role._id} value={role._id} label={role.name}>
                <span>{role.name}</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={t("generic.fields.lang")} name="lang">
          <Select defaultValue={String(user.lang)}>{langOptions}</Select>
        </Form.Item>

        <Form.Item style={{ marginBottom: "2px" }}>
          <Checkbox
            checked={selectAllTeams}
            onChange={handleSelectAllTeamsChange}
          >
            {t("team.newTeam.selectAllTeams")}
          </Checkbox>
        </Form.Item>

        <Form.Item name="teams" label={t("team.teams")}>
          <Select
            mode="multiple"
            disabled={selectAllTeams}
            onChange={handleTeamsChange}
            style={{ width: "100%" }}
            optionLabelProp="label"
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            filterOption={(typed, item) => {
              const contains = String(item.label)
                .toUpperCase()
                .startsWith(String(removeAccents(typed)).toUpperCase());
              return contains;
            }}
          >
            {teams.map((team) => (
              <Select.Option key={team._id} value={team._id} label={team.name}>
                <Tag color={team.color || "blue"}>{team.name}</Tag>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("generic.fields.updateUser")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
