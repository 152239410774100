import { Card, Layout, Pagination, Empty } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAppFilters,
  meetings,
  selectIsMeetingListReady,
  selectSearchPagination,
  updatePageAndSearch,
} from "./filters/slice";
import ListFilters from "./ListFilters";
import MeetingItem from "./Item";
import { useEffect, useState } from "react";
import __ from "../../app/i18n";
import LibraryModal from "../library/Modal";
import TagsModal from "../tags/TagsModal";
import ShareMeetingModal from "../share/ShareMeetingModal";
import { loadInteractionCallTypes } from "../team/interactionCallType/slice";
import { shallowEqual } from "react-redux";
const { Content } = Layout;

const loadingCards = (
  <>
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
  </>
);

export default function MeetingList() {
  const dispatch = useDispatch();
  const isMeetingListReady = useSelector(selectIsMeetingListReady);
  const pagination = useSelector(selectSearchPagination);
  const stateMeetings = useSelector(meetings, shallowEqual);
  const [meetingLibrary, setMeetingLibrary] = useState();
  const [shareMeeting, setShareMeeting] = useState();
  const [meetingTag, setMeetingTag] = useState();
  const [pageNumber, setPageNumber] = useState(pagination.page);
  const [pageSize, setPageSize] = useState(pagination.perPage);

  document.title = `${__("appName")} - ${__("meetings.label")}`;

  useEffect(() => {}, [stateMeetings]);
  useEffect(() => {
    dispatch(loadInteractionCallTypes());
    dispatch(loadAppFilters());
  }, [dispatch]);

  useEffect(() => {
    dispatch(updatePageAndSearch({ page: pageNumber, perPage: pageSize }));
  }, [dispatch, pageNumber, pageSize]);

  return (
    <Content style={{ padding: "0 25px" }}>
      <Layout
        className={"site-layout-background main-layout-container"}
        style={{ padding: "24px 0" }}
      >
        <LibraryModal
          meeting={meetingLibrary}
          open={!!meetingLibrary}
          onClose={() => setMeetingLibrary(null)}
        />
        <TagsModal
          meeting={meetingTag}
          open={!!meetingTag}
          onClose={() => setMeetingTag(null)}
        />
        <ShareMeetingModal
          meeting={shareMeeting}
          open={!!shareMeeting}
          onClose={() => setShareMeeting(null)}
        />
        <ListFilters />
        <Content style={{ padding: "0 24px", minHeight: 280 }}>
          {!isMeetingListReady
            ? loadingCards
            : (stateMeetings || []).map((meeting) => (
                <MeetingItem
                  key={`meetingItem_${meeting.id}_${meeting.callType}`}
                  onLibrary={() => setMeetingLibrary(meeting)}
                  onTag={() => setMeetingTag(meeting)}
                  onShare={() => setShareMeeting(meeting)}
                  meeting={meeting}
                />
              ))}
          {!(stateMeetings || []).length ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            ""
          )}
          <Pagination
            hideOnSinglePage
            onChange={(page) => setPageNumber(page)}
            onShowSizeChange={(_, size) => setPageSize(size)}
            defaultCurrent={1}
            pageSize={pageSize}
            total={pagination.total}
            pageSizeOptions={[10, 25, 50]}
          />
        </Content>
      </Layout>
    </Content>
  );
}
