import {
  CalendarTwoTone,
  TrophyTwoTone,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import { Avatar, Card, Badge, Typography, Space, Tooltip } from "antd";
import Meta from "antd/lib/card/Meta";
import styles from "./meetings.module.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectedWords } from "./filters/slice";
import { stringToColor } from "../../app/color";
import { getSecondsDiff } from "../../app/utils";
import useAgendaMeetingDetails from "../agenda/AgendaItemModal";
import "moment/locale/es";
import "moment/locale/pt";
import { SamuScoreItem } from "./SamuScoreItem";
import HubspotImg from "./hubspotMeetingLogo.svg";
import WhatsAppImg from "./whatsApp.svg";
import MeetingActions from "./actions";
import MeetingTags from "../tags/MeetingTags";
import ShareMeetingAction from "../share/ShareMeetingAction";
import MeetingProbabilityItem from "./MeetingProbabilityItem";
import { useState } from "react";
import SamuThumb from "./SamuThumb";
import { useTranslation } from "react-i18next";

export const firstUp = (str) =>
  str[0].toUpperCase() + str.substring(1, str.length);

function SummaryMeeting({ summary }) {
  const [ellipsis, setEllipsis] = useState(false);

  return (
    <Typography.Text
      style={{ display: "block", marginBottom: "10px", marginTop: "-5px" }}
      type="secondary"
      italic
    >
      {ellipsis ? (
        summary
      ) : (
        <>
          {String(summary).substring(0, 250)}...
          <a onClick={() => setEllipsis(true)}>Ver más</a>
        </>
      )}
    </Typography.Text>
  );
}

export default function Item(props) {
  const { seeMeetingDetails } = useAgendaMeetingDetails(() => {});
  const { i18n, t } = useTranslation();
  moment.locale(i18n.language);

  let find = useSelector(selectedWords) || "";
  let { id, img, name, host, matchText, dateFrom, dateTo, provider } =
    props.meeting;
  if (provider === "HUBSPOT") {
    img = HubspotImg;
  } else if (provider === "WHATSAPP") {
    img = WhatsAppImg;
  }

  matchText = find ? matchText : null;

  const participants = Array.from(new Set((matchText || []).map((m) => m.who)));

  const mapMatch = (text) => {
    text = removeAccents(text);
    find = removeAccents(find);
    const start = String(text).toUpperCase().indexOf(find.toUpperCase());

    if (start === -1) {
      return <span>{text}</span>;
    }

    return (
      <>
        <span>{text.split("").splice(0, start)}</span>

        <b>{text.split("").splice(start, find.length)}</b>

        <span>{text.split("").splice(start + find.length, text.length)}</span>
      </>
    );
  };

  function removeAccents(text) {
    const sustitutions = {
      àáâãäå: "a",
      ÀÁÂÃÄÅ: "A",
      èéêë: "e",
      ÈÉÊË: "E",
      ìíîï: "i",
      ÌÍÎÏ: "I",
      òóôõö: "o",
      ÒÓÔÕÖ: "O",
      ùúûü: "u",
      ÙÚÛÜ: "U",
      ýÿ: "y",
      ÝŸ: "Y",
      ß: "ss",
      ñ: "n",
      Ñ: "N",
    };
    // Devuelve un valor si 'letter' esta incluido en la clave
    function getLetterReplacement(letter, replacements) {
      const findKey = Object.keys(replacements).reduce(
        (origin, item) => (item.includes(letter) ? item : origin),
        false
      );
      return findKey !== false ? replacements[findKey] : letter;
    }
    // Recorre letra por letra en busca de una sustitución
    return text
      .split("")
      .map((letter) => getLetterReplacement(letter, sustitutions))
      .join("");
  }

  const matchTextContainer = (
    <>
      {(matchText || []).map((item) => {
        const { text, speaker: who } = item;

        let diff = item.time;
        let startAt = diff;
        if (["GOOGLE", "MICROSOFT"].includes(props.meeting.provider)) {
          diff = getSecondsDiff(new Date(dateFrom), new Date(item.startAt));
          startAt = item.startAt;
        }

        const time = moment(new Date("00"))
          .add(diff, "seconds")
          .format("HH:mm:ss");

        return (
          <Link to={`/meeting/${id}?time=${startAt}`}>
            <div className={styles.matchItem}>
              <span className={styles.matchItemBadge}>
                <Badge
                  style={{
                    backgroundColor:
                      stringToColor(who),
                  }}
                  count={time}
                />
              </span>
              <span className={styles.matchItemText}>
                <Typography.Title
                  type="secondary"
                  level={5}
                  className={styles.matchItemTitle}
                >
                  {who}
                </Typography.Title>
                <Typography.Text type="secondary">
                  {mapMatch(text)}
                </Typography.Text>
              </span>
            </div>
          </Link>
        );
      })}
    </>
  );

  let summary = props.meeting?.extractor?.samu_summary;

  const descriptionContainer = (
    <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
      {summary && summary.length > 10 ? (
        <li>
          <Space />
          <SummaryMeeting summary={summary} />
        </li>
      ) : (
        ""
      )}
      <li key={`${id}-meeting-description-date`}>
        <CalendarTwoTone />{" "}
        <Typography.Text type="secondary">
          {firstUp(moment(dateFrom).format("dddd").replace("º", "")) +
            " " +
            moment(dateFrom).format("Do").replace("º", "") +
            " de " +
            moment(dateFrom).format("MMMM") +
            " del " +
            moment(dateFrom).format("YYYY")}{" "}
          {props.meeting.provider !== "WHATSAPP" ? (
            <>
              ⋅ {moment(dateFrom).format("HH:mm")} -{" "}
              {moment(dateTo).format("HH:mm")}
            </>
          ) : (
            <></>
          )}
        </Typography.Text>
      </li>

      {props.meeting?.isHostUpdating ? (
        <>
          <LoadingOutlined style={{ color: "#1a91ff" }} spin />{" "}
        </>
      ) : (
        <li key={`${id}-meeting-description-host`}>
          <TrophyTwoTone style={{ marginBottom: 0 }} />{" "}
          <Typography.Title
            type="secondary"
            level={5}
            style={{
              display: "inline-block",
              color: "#198fff",
              marginBottom: 0,
            }}
          >
            {host}
          </Typography.Title>
        </li>
      )}

      <Space />
      {matchText ? matchTextContainer : ""}
    </ul>
  );

  const linkToMeeting = (children) => {
    if (props.meeting.provider === "WHATSAPP") {
      return <Link to={`/conversation/${props.meeting.id}`}>{children}</Link>;
    } else if (props.meeting && props.meeting.status) {
      return <Link to={`/meeting/${props.meeting.id}`}>{children}</Link>;
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() =>
          seeMeetingDetails({ ...props.meeting, _id: props.meeting })
        }
      >
        {children}
      </div>
    );
  };

  return (
    <Card
      style={{ width: "100%", marginBottom: "10px" }}
      className={"meetingItemActionContainer"}
      extra={
        <div className={styles.meetingItemActionContainer}>
          <MeetingActions
            meeting={props.meeting}
            onLibrary={() => props.onLibrary(props.meeting.id)}
            onTag={() => props.onTag(props.meeting.id)}
          />
        </div>
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-3px",
        }}
      >
        <Meta
          style={{ display: "inline-flex", minWidth: "500px" }}
          avatar={linkToMeeting(
            <div>
              <div className={styles.avatarMeetingDurationContainer}>
                <Avatar
                  src={img}
                  style={
                    props.meeting.provider === "WHATSAPP"
                      ? {
                          width: "150px",
                          backgroundSize: "cover",
                          height: "auto",
                          position: "relative",
                          top: "50%",
                          left: "50%",
                          marginTop: "30px",
                          transform: "translate(-50%, -50%)",
                        }
                      : {}
                  }
                  className={`avatar-img-${provider} meeting-list-thumb`.toLowerCase()}
                  icon={<SamuThumb />}
                />
                {props.meeting.duration ? (
                  <span className={styles.avatarMeetingDuration}>
                    {parseInt(props.meeting.duration)} min
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <MeetingProbabilityItem meeting={props.meeting} />
            </div>
          )}
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  marginRight: "10px",
                }}
              >
                {linkToMeeting(
                  <span style={{ color: "#000000d9" }}>{name}</span>
                )}
              </span>
              <MeetingTags meeting={props.meeting} />
              <ShareMeetingAction onShare={props.onShare} />
            </div>
          }
          description={descriptionContainer}
        />
        <SamuScoreItem meeting={props.meeting} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {props.meeting.status === "CALLTYPE_CHANGED" ? (
          <Typography.Text type="secondary" style={{ marginTop: "13px" }}>
          <LoadingOutlined style={{ color: "#1a91ff" }} spin />{" "}
          {t("meetings.details.sections.edit.callTypeChanged")}
          </Typography.Text>
        ) : (
          ""
        )}
        {props.meeting.seen ? (
          <div className={styles.seenIconContainer}>
            {props.meeting.seenAt ? (
              <Tooltip
                placement={"left"}
                title={t("meetings.seenAt", {
                  date: moment(props.meeting.seen).format("DD/MM/YYYY"),
                })}
              >
                <EyeOutlined /> {t("meetings.seen")}
              </Tooltip>
            ) : (
              <>
                <EyeOutlined /> {t("meetings.seen")}
              </>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </Card>
  );
}
