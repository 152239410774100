import { Checkbox, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import __ from "../../../app/i18n";
import {
  selectSelectedPermissionIds,
  setSelectedPermissionIds,
} from "../permissions/slice";
import { useDispatch, useSelector } from "react-redux";
import styles from "./role.module.css";
import { InfoCircleOutlined } from "@ant-design/icons";

export const Permission = ({ permission, selected, isPreview = false }) => {
  const dispatch = useDispatch();
  const [permissionState, setPermissionState] = useState(selected);
  const selectedPermissionIds = useSelector(selectSelectedPermissionIds);

  const handleChange = (e) => {
    setPermissionState(e.target.checked);
    if (e.target.checked) {
      dispatch(
        setSelectedPermissionIds([...selectedPermissionIds, permission._id])
      );
    } else {
      dispatch(
        setSelectedPermissionIds(
          selectedPermissionIds.filter((id) => id !== permission._id)
        )
      );
    }
  };

  useEffect(() => {
    setPermissionState(selected);
  }, [selected]);

  return (
    <div className={styles.permissionContainer}>
      <Checkbox checked={permissionState} onChange={(e) => {
        !isPreview && handleChange(e);
      }}>
        <Typography.Text className={styles.permissionName} key={permission.key}>
          {__(permission.name)}
        </Typography.Text>
        <Tooltip
          title={__(permission.description)}
          className={styles.permissionDescription}
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Checkbox>
    </div>
  );
};
